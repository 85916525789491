import React from 'react'
import { StyledFooter, StyledFooterContentWrapper, StyledFooterHeader } from './Footer.styles'
import linkData from '../Navbar/navLinks/linkData'

const Footer = () => {
  return (
    <>
        <StyledFooter>
            <StyledFooterContentWrapper>
              <StyledFooterHeader>
                <h3><span style={{color: 'red'}}>Dragon</span>Py</h3>
                <p>The best mobile bot for OSRS.</p>
              </StyledFooterHeader>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
              <ul style={{display: 'flex', gap: '2rem', flexWrap: 'wrap'}}>
                {
                  linkData.map((link) => (
                    <li key={link.id}> <a href={link.path} style={{color: 'white'}}>{link.text}</a> </li>
                  ))
                }
                <li> <a style={{color: 'white'}} href="https://discord.gg/uqyyHSBvqt">Discord</a> </li>
              </ul>
            </div>
            </StyledFooterContentWrapper>
            <p><em>Copyright 2023</em> • <strong> <a style={{ color: 'white' }} href="/tos">Terms of Service</a> </strong></p>
        </StyledFooter>
    </>
  )
}

export default Footer