import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { printDebug } from './utils/printDebug';
import { Provider } from 'react-redux';
import store from './redux/store';
import { GlobalStyles } from './GlobalStyles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
// injectStore from api to access the getState() in the api interceptor
import { injectStore } from './api/index';

const root = ReactDOM.createRoot(document.getElementById('root'));

process.env.NODE_ENV == 'production' && disableReactDevTools();

printDebug('index.js rendered');

// inject the store into the api to access the userAccessToken state
injectStore(store);

root.render(
  <>
    <div id='root' data-reactroot=''>
      <GlobalStyles />
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route path='/*' element={<App />} />
          </Routes>
        </Provider>
      </BrowserRouter>
    </div>
  </>
);
