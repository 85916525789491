import styled from 'styled-components';
import {
  dtMaxSize,
  ltMaxSize,
  mobileMaxSize,
  tabletMaxSize,
} from '../../../GlobalStyles.js';

export const BuyBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  /* margin-top: 200px; */
  align-items: center;

  @media only screen and (max-width: 1470px) {
    flex-direction: column !important;
    gap: 3rem;
    align-items: center;
    justify-content: center;
  }
  /* @media only screen and (max-width: ${ltMaxSize}) {
  }
  @media only screen and (max-width: ${tabletMaxSize}) {
  }
  @media only screen and (max-width: ${mobileMaxSize}) {
  } */
`;

export const SubBtnContainer = styled.div`
  display: flex;
  gap: clamp(1rem, 2vw, 3rem);

  @media only screen and (max-width: ${tabletMaxSize}) {
    flex-direction: column;
    gap: 4rem;
  }
`;

export const SubBtnBox = styled.div`
  background-color: #ebebeb;
  padding: 4rem 1.8rem;
  margin: 0 1rem;
  border-radius: 2rem;
  max-height: 22rem;
  min-width: 22rem;
  max-width: 22rem;
`;

export const SubBtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  padding: 0 2rem;
  gap: 2.25rem;
`;

export const PerkBox = styled.div`
  color: white;
  border-radius: 2rem;
  background-color: ${props => props.theme.bgPrimary};
  padding: 2.5rem 3rem;

  @media only screen and (max-width: ${mobileMaxSize}) {
    padding: 1.5rem;
    margin: 0 1rem;
  }
`;

export const PerkList = styled.ul`
  list-style-type: none;
  line-height: 2.25rem;
  /* font-size: 1.25rem; */
  font-size: clamp(0.5rem, 1vw, 1.2rem);
`;

export const PerkItem = styled.li`
  background-color: #303844e2;
  padding: 1rem;
  border-radius: 1rem;
  :not(:first-child) {
    margin-top: 1rem;
  }
`;

export const SubHeader = styled.h1`
  /* color: ${props => props.theme.bgPrimary}; */
  color: #303844e2;
`;

export const DisclaimerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding: 0 5rem;

  @media only screen and (max-width: ${mobileMaxSize}) {
    padding: 0 1.25rem;
  }
`;
