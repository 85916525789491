import styled from 'styled-components';
import { dtMaxSize } from '../../GlobalStyles';

export const StyledSubStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${dtMaxSize}) {
    margin-top: 3rem;
  }
`;
