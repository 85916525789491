import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { printDebug } from '../../utils/printDebug'

const RequireEmail = () => {
    const userId = useSelector(state => state.auth.userId)
    const isVerified = useSelector(state => state.auth.userIsVerified)
    const location = useLocation();

    useEffect(() => {
      printDebug(`[Require Email] - Rendered`)

    }, [])
    

  return (

    userId
        ? isVerified
            ? <Outlet />
            : <Navigate to='verifyEmail' state={{ from: location }} replace />
        : <Navigate to='/auth' state={{ from: location }} replace />
    
  )
}

export default RequireEmail