import React from 'react'
import { UpdatesFeedData } from './UpdatesFeedData'

const UpdatesFeed = ({ version, comment, date }) => {
  return (
    <div>
        <h3>🔧 Updates</h3>
        {
            UpdatesFeedData.map((updateItem, index) => (
                <div key={updateItem.id} style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '2rem' }}>
                    <p><strong>{updateItem.version}</strong> - <em>{updateItem.date.toString()}</em></p>
                    <p>{updateItem.comment}</p>
                    <ul style={{lineHeight: '2rem', listStyle: 'none'}}>
                      {updateItem.details && updateItem.details != '' && updateItem.details.map(updateDetail => (
                        <li key={updateDetail.id}>⦿ <em>{updateDetail.text}</em></li>
                      ))}
                    </ul>
                    <hr style={{ width: '50%', opacity: '50%'}} />
                </div>
            ))
        }
    </div>
  )
}

export default UpdatesFeed