import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  dtMaxSize,
  ltMaxSize,
  mobileMaxSize,
  tabletMaxSize,
} from '../../GlobalStyles';

export const NavContainer = styled.div`
  background-color: ${props => props.theme.bgPrimary};

  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  /* margin-top: -200px; */
  z-index: 999 !important;

  @media only screen and (max-width: ${dtMaxSize}) {
  }
  @media only screen and (max-width: ${ltMaxSize}) {
  }
  @media only screen and (max-width: ${tabletMaxSize}) {
  }
  @media only screen and (max-width: ${mobileMaxSize}) {
  }
`;

export const StyledNavWrapper = styled.div`
  width: 92vw;
  display: flex;
  align-items: center;

  @media (max-width: ${dtMaxSize}) {
    width: 80vw;
  }
`;

export const NavSection = styled.div`
  color: ${props => props.theme.textSecondary};
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  &.brand-logo {
    font-weight: 600;
    font-size: clamp(1.2rem, 1.5vw, 1.75rem);
    flex: 0.75;
  }
  &.mid {
    flex: 1.5;
  }
  &.right {
    flex: 0.75;
  }

  @media only screen and (max-width: ${dtMaxSize}) {
    &.right {
      /* justify-content: flex-end !important; */
      justify-content: flex-end !important;
      flex: 0.5;
      /* padding-right: 2rem; */
    }
    &.brand-logo {
      flex: 0.5;
      justify-content: flex-start !important;
    }

    ${props =>
      props.pos === 'mid' && {
        display: 'none',
      }}/* Toggle mobile menu display in this media query */
  }
`;

export const StyledNavLink = styled(Link)`
  text-decoration: none;
  font-weight: 500;
  color: white;

  &.active {
    color: ${props => props.theme.textPrimary} !important;
  }

  &.sign-in {
    display: flex;

    p {
      display: flex;
      align-items: center;
      margin-left: 1rem;
    }

    @media only screen and (max-width: ${tabletMaxSize}) {
      display: none;
    }
  }

  &:not(:last-child) {
    margin-right: clamp(0.2rem, 5vw, 6rem);
  }
  &:hover {
    color: ${props => props.theme.textPrimary};
  }
`;

export const NavSpan1 = styled.span`
  color: #e51f1a;
`;

export const NavSpan2 = styled.span`
  color: #b9c0bf;
`;

export const StyledRightNav = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${dtMaxSize}) {
    display: none;
  }
`;
