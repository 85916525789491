import styled from 'styled-components';
import { dtMaxSize } from '../../../GlobalStyles';

export const StyledAccountPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media (max-width: ${dtMaxSize}) {
    justify-content: center;
    align-items: center;
  }
`;

export const AccountPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* justify-content: center; */
  align-items: center;
  /* margin-top: 6rem; */
`;

export const AccountPageContainer = styled.div`
  display: flex;

  @media (max-width: ${dtMaxSize}) {
    flex-direction: column;
    width: 70vw;
  }

  @media (min-width: ${dtMaxSize}) {
    .left {
      width: 50%;
      display: flex;
      justify-content: center;
    }
    .right {
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }
`;
