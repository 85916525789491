import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import NavLinks from '../navLinks/NavLinks'
import { StyledBurger, StyledMenu, StyledMobileMenu } from './MobileMenu.styles'
import MobileNavLinks from './mobileNavLinks/MobileNavLinks'

const MobileMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(true)
    const userId = useSelector(state => state.auth.userId)

    const toggleMenuOpen = () => {
        setIsMenuOpen(prev => !prev)
    }

    useEffect(() => {

    }, [userId])

  return (
    <>
        <StyledMobileMenu>
            <StyledBurger isOpen={isMenuOpen} onClick={toggleMenuOpen}>
                <div className='top' style={{ height: '4px', width: '40px', background: 'white', borderRadius: '5rem'}}></div>
                <div className='middle' style={{ height: '4px', width: '40px', background: 'white', borderRadius: '5rem'}}></div>
                <div className='bottom' style={{ height: '4px', width: '40px', background: 'white', borderRadius: '5rem'}}></div>
            </StyledBurger>
            <StyledMenu isOpen={isMenuOpen}>
                <MobileNavLinks toggleMenuOpen={toggleMenuOpen} />
            </StyledMenu>
        </StyledMobileMenu>

    </>
  )
}

export default MobileMenu