import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/Auth.slice';
import alertReducer from './slices/Alert.slice';
import adminDashReducer from './slices/AdminDash.slice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    alert: alertReducer,
    adminDash: adminDashReducer,
  },
  devTools: process.env.NODE_ENV == 'production' ? false : true,
});

export default store;
