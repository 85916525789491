import styled from 'styled-components';
import { tabletMaxSize } from '../../../GlobalStyles';
import { Avatar, Button } from '@mui/material';

export const StyledAuthComponent = styled.div`
  background: rgba(255, 255, 255, 0.55);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 4rem;
  border-radius: 15px;
  max-width: 32rem;
`;

export const StyledAuthButton = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  font-size: 0.9rem;
  cursor: pointer;

  &:hover {
    color: gray;
  }
`;

export const StyledAvatar = styled(Avatar)`
  background: ${props => props.theme.bgPrimary} !important;
  color: ${props => props.theme.textSecondary} !important;
`;

export const StyledSubmitButton = styled(Button)`
  background: none;
  padding: 1rem 2rem;
  border-radius: 6px;
  border: 1px solid black;
  margin-top: 2rem !important;
  height: 3.5em;

  &:hover {
    &.pulsate-fwd {
      -webkit-animation: pulsate-fwd 0.75s ease-in-out infinite both;
      animation: pulsate-fwd 0.75s ease-in-out infinite both;
    }
    @-webkit-keyframes pulsate-fwd {
      0% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      50% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
      }
      100% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
    @keyframes pulsate-fwd {
      0% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      50% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
      }
      100% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
`;
