import React, {useState, useEffect} from 'react'
import { printDebug } from '../../../utils/printDebug';
import BuyPage from '../../UnAuthed/Buy/BuyPage';
import Message from './Success/Message';
import Success from './Success/Success';

const Stripe = () => {
    let [message, setMessage] = useState('');
    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState('')
  
    useEffect(() => {
      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search);
      setSessionId(query.get('session_id'))

      printDebug(`query: ${query}`);
      printDebug(`sessionId: ${sessionId}`);
  
      if (query.get('success')) {
        setSuccess(true);
        //TODO: Send request to update user doc with license
      }
  
      if (query.get('canceled')) {
        setSuccess(false);
        setMessage("Order Canceled - License not issued. Please try your purchase again.");
      }
    }, [sessionId]);
  
    return (
      <div style={{ width: '100vw', display: 'flex', justifyContent: 'center' }}>
        {
          !success && message === '' 
            ? <BuyPage /> 
            : success && sessionId !== '' 
              ? <Success text={'Subscription to DragonPy Successful!'} /> 
              : <Message message={message} />
        }
      </div>
    )
}

export default Stripe