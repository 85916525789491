import React from 'react'
import GuideTemplate from '../GuideTemplate'

const BlueStacksGuide = () => {
  return (
    <GuideTemplate header={'BlueStacks (Pie 64-bit)'}>
        <p>You should already have BlueStacks installed from verifying your software compatability prior to purchasing. If not - <a href="/setup">click here</a>.</p>
    </GuideTemplate>
  )
}

export default BlueStacksGuide