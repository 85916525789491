import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { printDebug } from '../../utils/printDebug';
import useAuth from '../../hooks/useAuth'
import { setUserIsLoading } from '../../redux/slices/Auth.slice';

const RequireAuth = ({ permittedRoles }) => {
  const authState = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const userIsLoading = useSelector(state => state.auth.userIsLoading)
  const accessToken = useSelector(state => state.auth.accessToken)
  const authed = localStorage.getItem('isAuthed') === 'true';

  // debugger

    useEffect(() => {
      // printDebug(`🔐 [RequireAuth] - Rendered! authState: ${JSON.stringify(authState)}`);
      // printDebug(`🔐 [RequireAuth] - userIsLoading: ${userIsLoading}`);

    }, [])

    useEffect(() => {
      // printDebug(`🔐 [RequireAuth] - RE-RENDER userIsLoading: ${userIsLoading}`);
      // if (authState.accessToken && userIsLoading) {
      //   printDebug(`THIS SHOULD FIRE 🔥`)
      //   dispatch(setUserIsLoading(false))
      // }
    }, [userIsLoading, accessToken])

  // debugger;
  return (

    !authState.userIsLoading && authed &&
      authState.userAccessToken
        // If authState.userAccessToken exists
        ? authState?.userRoles?.find(role => permittedRoles?.includes(role))
          // and if the authState.userRoles are among the permittedRoles for the current route - Show the outlet
          ? <Outlet />
          // otherwise the authenticated user is not permitted
          : <Navigate to='/unauthorized' state={{ from: location }} replace />
        // authState.userAccessToken does NOT exist (No User)
        : <Navigate to='/auth' state={{ from: location }} replace />
  )
}

export default RequireAuth