import React, {useState, useEffect} from 'react'
import { NewsFeedData } from './NewsFeedData'

const NewsFeed = () => {
  const [newsItems, setNewsItems] = useState()

  useEffect(() => {

  }, [newsItems])
  
  return (
    <div>
      <h3>📰 News</h3>
      <div style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        {
          NewsFeedData.map((newsItem, index) => (
            <div key={newsItem.id} style={{ display: 'flex', flexDirection: 'column', gap: '1rem'}}>
              <p>{newsItem.comment}</p>
              <p> <em>{newsItem.date}</em> </p>
              <hr style={{ width: '50%', opacity: '50%', marginBottom: '1rem'}} />
            </div>
            ))
          }
      </div>

    </div>
  )
}

export default NewsFeed