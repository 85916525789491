import React from 'react'
import virtualImg from '../../../assets/virtualization.png'
import installationImg from '../../../assets/pie64installer.png'
import settingsImg from '../../../assets/settingsArrow.png'
import displayImg from '../../../assets/displaySettings.png'
import graphicsImg from '../../../assets/graphicsSettings.png'
import preferenceImg from '../../../assets/preferenceSettings.png'
import appImg from '../../../assets/osrsApp.png'


const SetupPage = () => {
  return (
    <>
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem', marginTop: '5vh'}}>
           <h1>Setup & Requirements Verification</h1>
            <hr style={{width: '50%', opacity: '25%'}} />
           <ol style={{ lineHeight: '5rem'}}>
               <li>
                    <h3>Ensure Virtualization is enabled on your system - <a href="https://support.bluestacks.com/hc/en-us/articles/360058102252-How-to-enable-Virtualization-VT-on-Windows-10-for-BlueStacks-5">Click Here</a> if not</h3>
                    <p>press CTRL + SHFT + ESC to open the Task Manager</p>
                    <img src={virtualImg} alt="" style={{ width: '20vw'}} />
               </li>

               <li>
                <h3>Install BlueStacks 5 Mobile (Android) Emulator (Pie 64-Bit) <a href="https://www.bluestacks.com/download.html">from here</a></h3>
                <img src={installationImg} alt="" style={{ width: '40vw'}} />
               </li>
               
               <li>
                   <h3>Adjust BlueStacks 5 settings</h3>
                   <p>a. Open 'Settings' tab</p>
                   <img src={settingsImg} alt="" style={{ width: '60vw'}} />
                   <p>b. In the 'Display' tab, change the Display Resolution to 1920 x 1080 and Pixel Density to 320 DPI if not already set, and save.</p>
                   <img src={displayImg} alt="" style={{ width: '30vw'}} />
                   <p>c. In the 'Graphics' tab, change the Graphics Renderer to OpenGL, if not already set.</p>
                   <img src={graphicsImg} alt="" style={{ width: '30vw'}}  />
                   <p>d. In the 'Preferences' tab, disable Allow BlueStacks to Show Ads During Gameplay</p>
                   <img src={preferenceImg} alt="" style={{ width: '30vw'}}  />
               </li>
               
               <li>
                   <h3>Install OSRS Mobile App from Google Play Store</h3>
                   <img src={appImg} alt="" style={{ width: '60vw'}}  />
               </li>
           </ol>
           <p>Once you're able to run Old School RuneScape from the BlueStacks 5 client, you're ready to start botting with DragonPy</p>
       </div>
    </>
  )
}

export default SetupPage