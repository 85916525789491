import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { printDebug } from '../utils/printDebug';

const useAuth = () => {
  const authState = useSelector(state => state.auth);

  useEffect(() => {
    // printDebug(
    //   `🎣 [useAuth hook] - RE-RENDERED due to authState change: ${JSON.stringify(
    //     authState
    //   )}`
    // );
  }, [authState]);

  return authState;
};

export default useAuth;
