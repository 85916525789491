import React from 'react'
import LicenseCount from '../../../../../components/LicenseCount/LicenseCount'

const LicenseExpiration = ({ count, incCount, decCount }) => {
  return (
    <div>
        <LicenseCount count={count} incCount={incCount} decCount={decCount} labelText={'Days'} />
    </div>
  )
}

export default LicenseExpiration