const authed = localStorage.getItem('isAuthed') === 'true';

export default [
  authed
    ? {
        id: 1,
        text: 'Home',
        path: '/home',
        isActive: true,
      }
    : {
        id: 1,
        text: 'Home',
        path: '/',
        isActive: true,
      },
  {
    id: 2,
    text: 'Buy',
    path: '/buy',
    isActive: false,
  },
  {
    id: 3,
    text: 'Install',
    path: '/install',
    protected: true,
    isActive: false,
  },
  {
    id: 4,
    text: 'About',
    path: '/about',
    isActive: false,
  },
  {
    id: 5,
    text: 'Reviews',
    path: '/reviews',
    isActive: false,
  },
];
