import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { ContentContainer } from '../../../GlobalStyles'
import { StyledAuthComponent, StyledAuthButton, StyledSubmitButton } from './AuthPage.styles'
import AuthInput from './AuthInput/AuthInput';
import AuthHeader from './AuthHeader/AuthHeader';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, registerUser, requestRecovery, setAuthFormState, setUserIsLoading, socialSignIn } from '../../../redux/slices/Auth.slice';
import { setAlert } from '../../../redux/slices/Alert.slice';
import useAuth from '../../../hooks/useAuth'
import useAlert from '../../../hooks/useAlert'
import jwtDecode from 'jwt-decode'
import { printDebug } from '../../../utils/printDebug'



const AuthPage = () => {
  // debugger
  // VARIABLES
  // Three states include: 'register' | 'login' | 'recover'
  const [formData, setFormData] = useState({
    firstName : '',
    lastName : '',
    email : '',
    password : '',
    confirmPass: ''
  })
  const authFormState = useSelector(state => state.auth.authFormState)
  const { userId, userIsVerified, userAccessToken } = useAuth();
  const navigate = useNavigate()
  const location = useLocation()
  // Get where use came from in case of being redirected to put them back there once we sign in
  // ToDo - This needs to be in the Email Verification Page
  // const from = location.state?.from?.pathname || "/"
  const [showPass, setShowPass] = useState(false)
  const dispatch = useDispatch()
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,}$/;
  const isAuthed = localStorage.getItem('isAuthed')

  const toggleAlert = useAlert();

  // METHODS
  const handleFormToggle = (formType) => {
    switch (formType) {
      case 'login':
        try {
          dispatch(setAuthFormState('login'))
        } catch (error) {
          dispatch(setAlert({ isOpen: true, message: error.message, severity: 'error'}))          
        }
        finally {
          printDebug(`Setting userIsLoading from AuthPage`);
          dispatch(setUserIsLoading(false))
        }
        break;
      case 'recover':
        dispatch(setAuthFormState('recover'))
        break;
      case 'register':
        dispatch(setAuthFormState('register'))
        break;
    }
  }

  const handleShowPass = () => {
    setShowPass(!showPass)
  }

  const handleChange = (e) => {
    setFormData({ ... formData, [e.target.name] : e.target.value })
  }

  const handleAuthSubmit = async (e) => {
    e.preventDefault();
    try {
      switch(authFormState) {
        case 'register':
          formData.password === formData.confirmPass ? (
            //PWD_REGEX.test(formData.password)
            true ?
            dispatch(registerUser(formData)) : toggleAlert('Password must be 8 characters minimum with at least one upper-case and one special character.', 'error')
          ) : (
            toggleAlert('Passwords must match', 'error')
            // dispatch(setAlert({ isOpen: true, message: 'Passwords must match.', severity: 'error'}))
          )
          break;
        case 'login':
          formData.email !== null && formData.password !== null
            ? dispatch(loginUser(formData)) 
            : dispatch(setAlert({ isOpen: true, message: 'Something went wrong. Please try again.', severity: 'error' }))
            break;
        case 'recover':
          formData.email !== null
            ? dispatch(requestRecovery({ email: formData.email }))
            : toggleAlert('Please provide the email to which we will send a recovery link.', 'warning')
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(`❌ [Auth Page]: Catching error from dispatch(registerUser()) ${error}`)
      // dispatch(setAlert({ isOpen: true, message: error.message, severity: 'error' }))
      toggleAlert(error.message, 'error')
    }
  }

  // const initializeGoogleSignIn = () => {
  //   /* global google */
  //   google.accounts.id.initialize({
  //     client_id: '804365202893-c5aqoeh1bcfaj16bgskuln0vgf2milbc.apps.googleusercontent.com',
  //     callback: handleGoogleSignIn,
  //   })

  //   google.accounts.id.renderButton(
  //     document.getElementById("signInDiv"),
  //     { theme: 'outlined', size: "large", width: 400 }
  //   )
  // }

  // const handleGoogleSignIn = (response) => {
  //   const decodedToken = jwtDecode(response.credential)
  //   const googleUser = {
  //     name: decodedToken.name,
  //     email: decodedToken.email,
  //     profileImg: decodedToken.picture,
  //     _id: decodedToken.sub
  //   }
  //   if(googleUser._id) dispatch(socialSignIn(googleUser))

  // }

  useEffect(() => {
    printDebug(`📃 [AuthPage] - Rendered! userAccessToken: ${userAccessToken}`);
  }, [])

  useEffect(() => {
    printDebug(`📃 [AuthPage] - Update due to authFormState change - userAccessToken: ${userAccessToken}`);
    // if (authFormState === 'login' || authFormState === 'register') initializeGoogleSignIn()
  }, [authFormState])

  useEffect(() => {
    printDebug(`📃 [AuthPage] - Updated due to userAccessToken value change: ${userAccessToken}`);
    if (userAccessToken != null) {
      printDebug(`[AuthPage] - userAccessToken: ${userAccessToken}`);
      printDebug(`[AuthPage] - location: ${JSON.stringify(location)}`);
      printDebug(`[AuthPage] - userIsVerified: ${userIsVerified}`);
      userIsVerified 
        // If the user is verified...
        ? location?.state && location?.state?.from?.pathname !== '/auth'
          ? navigate(location?.state?.from?.pathname)
          : navigate('/home')
        // Otherwise user is NOT (email) verified - redirect to /verifyEmail page 
        : navigate('/verifyEmail')
    }
  }, [userAccessToken, isAuthed])
  
  return (
    <>
      <ContentContainer>
        <StyledAuthComponent>

          <AuthHeader authState={authFormState} />

          <form onSubmit={handleAuthSubmit} style={{ display: 'flex', flexDirection: 'column'}}>

            <Grid container spacing={2}>
              {authFormState === 'register' && (
                <>
                  <AuthInput name='firstName' label='First Name' half autoFocus handleChange={handleChange} />
                  <AuthInput name='lastName' label='Last Name' half handleChange={handleChange}/>
                </>
              )}
              {(authFormState === 'recover' || authFormState === 'login' || authFormState === 'register') && (
                <>
                  <AuthInput name='email' type='email' label='Email' handleChange={handleChange} style={{ minWidth: '20rem !important'}} />
                </>
              )}
              {(authFormState === 'login' || authFormState === 'register') && (
                <> 
                  <AuthInput name='password' label='Password' type={showPass ? 'text' : 'password'} autoComplete='on' handleShowPass={handleShowPass} handleChange={handleChange} />
                </>
              )}
              {authFormState === 'register' && (
                <>
                  <AuthInput name='confirmPass' label='Confirm Password' type='password' autoComplete='on' handleChange={handleChange} />
                </>
              )}
            </Grid>

            <StyledSubmitButton className='pulsate-fwd' variant='outlined' type='submit' style={ authFormState === 'recover' ? { width: '22rem' } : {}} >
              {
                authFormState === 'login' ? (
                  'Log in'
                ) : (
                  authFormState === 'recover' ? (
                    'Request Recovery'
                  ) : (
                    'Register'
                  )
                )
              }
            </StyledSubmitButton>

            {/* {
              authFormState === 'login' &&
              (
                <div id='signInDiv' style={{ marginTop: '2rem', caretColor: 'rgba(0,0,0,0)' }}>
                </div>
              )
            } */}

          </form>

          <Typography variant='p'> { authFormState === 'login' ? (
            <div style={{ display: 'flex', gap: '3rem'}}>
              <StyledAuthButton onClick={() => handleFormToggle('register')}>
                <p>Need to register?</p>
              </StyledAuthButton>
              <StyledAuthButton onClick={() => handleFormToggle('recover')}>
                <p>Forgot info?</p>
              </StyledAuthButton>
            </div>

          ) : authFormState === 'register' ? (
            <StyledAuthButton onClick={() => handleFormToggle('login')}>
              <p>Already have an account?</p>
            </StyledAuthButton>
          ) : (
            <StyledAuthButton onClick={() => handleFormToggle('login')}>
              <p>Back to Log-in</p>
            </StyledAuthButton>
          )} </Typography>
        </StyledAuthComponent>
      </ContentContainer>
    </>
  )
}

export default AuthPage