import moment from 'moment';

export const NewsFeedData = [
  {
    id: '1',
    date: 'Mon May 1 2023',
    comment:
      'Completing testing for desktop client and scripts and refining GUI for release Alpha release',
  },
];
