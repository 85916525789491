import React, { useEffect, useState } from 'react'
import { printDebug } from '../../utils/printDebug';
import { StyledReviewBox } from './ReviewBox.styles'

const ReviewBox = ({name, comment, rating}) => {
    const stars = '\u2B50'.repeat(rating);

    useEffect(() => {
      printDebug(`[Review Box] - Rendered`);
      }, []);

  return (
    <StyledReviewBox>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center'}}>
            <p>{comment}</p>
            <div style={{ display: 'flex', justifyContent: 'center'}}>
            {stars}
            </div>
            <p>- <span>{name}</span></p>
        </div>
    </StyledReviewBox>
  )
}

export default ReviewBox