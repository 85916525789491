import * as AuthAPI from '../api/APIs/Auth.api';
import { useDispatch } from 'react-redux';
import { setUserFromToken, setUserIsLoading } from '../redux/slices/Auth.slice';
import useAlert from './useAlert';
import { printDebug } from '../utils/printDebug';

const useRefreshTokens = () => {
  const dispatch = useDispatch();
  const toggleAlert = useAlert();

  const refresh = async () => {
    // debugger;

    // dispatch(setUserIsLoading(true));

    printDebug(
      `🔃 [refresh hook] - fired. Checking local storage to see if we need new access token...`
    );

    try {
      if (localStorage.getItem('isAuthed') === 'true') {
        printDebug(
          `🔃 [refresh hook] - isAuthed: true - Getting new Access Token...`
        );
        const response = await AuthAPI.refreshAccessToken();
        dispatch(setUserFromToken(response.data.accessToken));
        return response.data.accessToken;
      } else {
        printDebug(`[refresh hook] - isAuthed: false - Returning...`);
        return;
      }
    } catch (error) {
      printDebug(`⛔ [useRefresh hook] - refresh() ERR: ${error}`);
      toggleAlert(error.response.data.errorMessage, 'warning');
      localStorage.removeItem('isAuthed');
    } finally {
      printDebug(`🔃 [useRefresh hook] - finally (setUserIsLoading(false))`);
      // dispatch(setUserIsLoading(false));
    }
  };
  // debugger;
  return refresh;
};

export default useRefreshTokens;
