import styled from 'styled-components';
import { dtMaxSize, mobileMaxSize } from '../../GlobalStyles';

export const StyledFooter = styled.div`
  /* position: absolute; */
  width: 100%;
  height: 16vh;
  @media (max-width: ${mobileMaxSize}) {
    height: 20vh;
  }
  /* bottom: 0; */
  background-color: ${props => props.theme.bgPrimary};
  margin-top: 5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  color: ${props => props.theme.textSecondary};
`;

export const StyledFooterContentWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  @media (max-width: ${dtMaxSize}) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

export const StyledFooterHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  @media (max-width: ${dtMaxSize}) {
    flex-direction: row;
  }
`;
