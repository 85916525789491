import React from 'react';
import styled, { keyframes } from 'styled-components';

const floatAnimation = keyframes`
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -8px);
  }
  100% {
    transform: translate(0, 0);
  }
`;

const ImageWrapper = styled.div`
  display: inline-block;
  animation: ${floatAnimation} 3s infinite ease-in-out;

  &:hover {
    animation-play-state: paused;
  }
`;

const Image = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
`;

function FloatingImage({ src }) {
  return (
    <ImageWrapper>
      <Image src={src} />
    </ImageWrapper>
  );
}

export default FloatingImage;
