import jwtDecode from 'jwt-decode';
import { printDebug } from '../utils/printDebug';

export const setStateFromToken = (state, action) => {
  const accessToken = action.payload;
  const decodedJwt = jwtDecode(accessToken);

  // debugger;
  printDebug(
    `[utils] - setStateFromToken: JwtDecoded = ${JSON.stringify(decodedJwt)}`
  );

  const {
    userId,
    userName,
    userEmail,
    userRoles,
    userIsVerified,
    userProfileImg,
    userIsSocial,
    userStripe,
  } = decodedJwt;

  state.userId = userId;
  state.userName = userName;
  state.userEmail = userEmail;
  state.userRoles = userRoles;
  state.userIsVerified = userIsVerified;
  state.userProfileImg = userProfileImg;
  state.userIsSocial = userIsSocial;
  state.userAccessToken = accessToken;
  state.userStripe = userStripe;
  state.userIsLoading = false;

  return state;
};
