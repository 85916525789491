import { useDispatch } from 'react-redux';
import { setAlert } from '../redux/slices/Alert.slice';

const useAlert = () => {
  const dispatch = useDispatch();

  const toggleAlert = (message, severity) => {
    dispatch(setAlert({ isOpen: true, message, severity }));
  };
  return toggleAlert;
};

export default useAlert;
