import { createSlice } from '@reduxjs/toolkit';
import { printDebug } from '../../utils/printDebug';

const adminDash = createSlice({
  name: 'adminDash',
  initialState: {
    selectedUsers: [],
    isModalOpen: false,
  },
  reducers: {
    setSelectedUsers: (state, action) => {
      printDebug(
        `setSelectedUser reducer fired: action.payload = ${action.payload}`
      );
      state.selectedUsers = action.payload;
    },

    handleModalOpen: state => {
      printDebug(`Admin Dash Slice reducer opening modal`);
      state.isModalOpen = true;
    },

    handleModalClose: state => {
      printDebug(`Admin Dash Slice reducer closing modal`);
      state.isModalOpen = false;
    },
  },
});

// Selector for dynamic data shapes
export const getSelectedUsers = state => {
  return state?.selectedUsers;
};

export const { setSelectedUsers, handleModalOpen, handleModalClose } =
  adminDash.actions;
export default adminDash.reducer;
