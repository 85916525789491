import React from 'react'
import GuideTemplate from '../GuideTemplate'

const ScriptGuides = () => {
  return (
    <GuideTemplate header='Scripts'>
        <h3><em>Individual Scripts</em></h3>
        <p>🔨 Under construction</p>
    </GuideTemplate>
  )
}

export default ScriptGuides