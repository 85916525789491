import { createSlice } from '@reduxjs/toolkit';

const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    message: null,
    severity: null,
    isOpen: false,
  },
  reducers: {
    setAlert: (state, action) => {
      const { message, severity, isOpen } = action.payload;
      state.message = message;
      state.isOpen = isOpen;
      state.severity = severity;
    },
  },
});

// Selector for dynamic data shapes
export const selectAlertMessage = state => {
  return state?.message;
};

export const selectAlertIsOpen = state => {
  return state?.isOpen;
};

export const selectAlertSeverity = state => {
  return state?.severity;
};

export const { setAlert } = alertSlice.actions;
export default alertSlice.reducer;
