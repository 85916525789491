import React from 'react'
import { HomeContainer, HomeContainerSection, StyledSkillScriptsContainer } from './LandingPage.styles'
import splashImg from '../../../assets/splash_image.png'
import progressImage from '../../../assets/progress_img.png'
import goldImage from '../../../assets/gold_image.png'
import minigamesImage from '../../../assets/minigames.png'
import { Button } from '@mui/material'
import { moneyMakers } from './data/supportedMoneyMakers'
import { antibanMeasures } from './data/antibanMeasures'
import { supportedMinigames } from './data/supportedMinigames'
import skillsImage from '../../../assets/skills_image.png'
import antibanImage from '../../../assets/client_break_timer.png'
import ImageFadeUp from '../../../components/ImageFadeUp/ImageFadeUp'

const LandingPage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem'}}>

      {/* SPLASH */}
      
      <HomeContainer containerSize="1fr 1fr">
        <HomeContainerSection>
          <ImageFadeUp src={splashImg} alt={'splash_img'} imgWidth={'90%'} />
          {/* <img src={splashImg} alt="dragonpy client" style={{width: '100%'}} /> */}
        </HomeContainerSection>
        <HomeContainerSection style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '4rem'}}>
          <div>
            <h1 style={{padding: '0.5rem'}}>Simple. Effective.<span style={{ color: '#17bd2d'}}> Undetected.</span> </h1>
            <h1 style={{padding: '0.5rem', color: '#b9c0bf'}}>OSRS Mobile Bot</h1>
            <p style={{padding: '0.5rem', color: '#b9c0bf'}}>(Official OSRS Mobile App ➡ BlueStacks 5 Emulator ➡ Windows System)</p>
          </div>
          <div style={{display: 'flex', gap: '4rem'}}>
            <Button href='/buy' variant='contained' color='success' size='large'>
              <p style={{ fontSize: '1.25rem'}}>Buy Now</p>
            </Button>

            <Button href='/reviews' variant='outlined' size='large'>
            <p style={{ fontSize: '1.25rem'}}>Reviews</p>
            </Button>
          </div>
          
        </HomeContainerSection>
      </HomeContainer>

      <div style={{ display: 'flex', justifyContent: 'center', opacity: '25%', marginTop: '6rem', marginBottom: '6rem'}}>
        <hr style={{width: '50%'}} />
      </div>

      <HomeContainer containerSize='1.2fr 1fr'>
        <HomeContainerSection alignItems='flex-start' style={{padding: '0 0 0 4rem'}}>
          <div>
            <h1 style={{fontSize: '2.25rem'}}>Create a <span style={{ textDecoration: 'underline'}}>Maxed</span> OSRS Account </h1>
            <h1 style={{fontSize: '2.25rem'}}>in <span style={{color: '#17bd2d'}}>Months</span> NOT Years.</h1>
            <br />
            <hr style={{width: '50%', opacity: '30%', margin: '2rem 0 2rem'}} />
            <br />

            <div style={{display: 'flex', flexDirection: 'column', gap: '4rem', fontSize: '.7rem'}}>
                <h1><span style={{background: '#17bd2d1a', borderRadius: '100%', padding: '1rem 1.5rem', marginRight: '1rem', color: '#17bd2d'}}>✔</span> <em style={{ padding: '.7rem', borderRadius: '1rem'}}> ~$1 per script</em> </h1>
                <h1><span style={{background: '#17bd2d1a', borderRadius: '100%', padding: '1rem 1.5rem', marginRight: '1rem', color: '#17bd2d'}}>✔</span> <em style={{ padding: '.7rem', borderRadius: '1rem'}}>Rapid Progression</em> </h1>
                <h1><span style={{background: '#17bd2d1a', borderRadius: '100%', padding: '1rem 1.5rem', marginRight: '1rem', color: '#17bd2d'}}>✔</span> <em style={{ padding: '.7rem', borderRadius: '1rem'}}>Undetected Operation</em> </h1>
                <h1><span style={{background: '#17bd2d1a', borderRadius: '100%', padding: '1rem 1.5rem', marginRight: '1rem', color: '#17bd2d'}}>✔</span> <em style={{ padding: '.7rem', borderRadius: '1rem'}}>Most Reliable Account Builder</em> </h1>
            <div style={{ display: 'flex', marginTop: '2rem', gap: '2rem', justifyContent: 'flex-end'}}>
                <Button variant='contained' color='success' size='large' href='/buy'>Start Botting</Button>
                <Button variant='outlined' color='primary' size='large' href='/about'>About</Button>
            </div>
          </div>

            <br />
            <br />

            <p></p>
          </div>
        </HomeContainerSection>
        <HomeContainerSection alignItems='flex-start'>
          <div style={{display: 'flex', flexDirection: 'column', gap: '5rem'}}>
            {/* <img src={progressImage} alt="progress_img" style={{width: '70%'}} /> */}
          <ImageFadeUp src={progressImage} alt={'splash_img'} imgWidth={'70%'} />

          </div>

        </HomeContainerSection>
      </HomeContainer>


      <div style={{ display: 'flex', justifyContent: 'center', opacity: '25%', marginTop: '6rem', marginBottom: '6rem'}}>
        <hr style={{width: '50%'}} />
      </div>

      {/* SKILLS */}


      <HomeContainer containerSize='1.2fr 1fr'>
        <HomeContainerSection>
          <div>
            <h1>50+</h1>
            <h1>Skill Scripts</h1>
            <StyledSkillScriptsContainer>
              <div>
                <ul style={{listStyleType: 'none', display: 'flex', flexDirection: 'column', gap: '.75rem'}}>
                  <li>Combat</li>
                  <li>Agility</li>
                  <li>Mining</li>
                  <li>Smithing</li>
                  <li>Fishing</li>
                  <li>Cooking</li>
                </ul>
              </div>
              <div>
                <ul style={{listStyleType: 'none', display: 'flex', flexDirection: 'column', gap: '.75rem'}}>
                  <li>Crafting</li>
                  <li>Herblore</li>
                  <li>Thieving</li>
                  <li>Fletching</li>
                  <li>Firemaking</li>
                  <li>Woodcutting</li>
                </ul>
              </div>
              <div>
                <ul style={{listStyleType: 'none', display: 'flex', flexDirection: 'column', gap: '.75rem'}}>
                  <li>Hunter</li>
                  <li>Farming</li>
                  <li>Runecrafting</li>
                  <li>Construction</li>
                  <li>Prayer</li>
                </ul>
              </div>
            </StyledSkillScriptsContainer>
            <div>
              {/* CTA Buttons */}
              <div style={{ display: 'flex', gap: '2rem', marginTop: '4rem', justifyContent: 'flex-start'}}>
                <Button variant='contained' color='success' size='large' href='/buy'>Start Botting</Button>
                <Button variant='outlined' color='primary' size='large' href='/about'>About Scripts</Button>
            </div>
            </div>
          </div>
        </HomeContainerSection>
        <HomeContainerSection>
          <ImageFadeUp src={skillsImage} alt={'skillsImage'} imgWidth={'30%'} />
        </HomeContainerSection>
      </HomeContainer>

      <div style={{ display: 'flex', justifyContent: 'center', opacity: '25%', marginTop: '6rem', marginBottom: '6rem'}}>
        <hr style={{width: '50%'}} />
      </div>

      {/* Money Making */}


      <HomeContainer containerSize='1fr 1fr'>
        <HomeContainerSection>
          <ImageFadeUp src={goldImage} alt={'goldImage'} imgWidth={'30%'} />
        </HomeContainerSection>
        <HomeContainerSection>
          <div>
            <h1>5+</h1>
            <h1>Money Makers</h1>
            <div style={{marginTop: '1.5rem', color: '#b9c0bf'}}>
              {
                moneyMakers.map((method, idx) => {
                  return <h2 key={idx} style={{marginBottom: '1rem'}}>{method.methodName}</h2>
                })
              }
            </div>
            <div style={{ display: 'flex', gap: '2rem', marginTop: '4rem', justifyContent: 'flex-start'}}>
                <Button variant='contained' color='success' size='large' href='/buy'>Start Botting</Button>
                <Button variant='outlined' color='primary' size='large' href='/about'>About Scripts</Button>
            </div>
          </div>
        </HomeContainerSection>
      </HomeContainer>

      <div style={{ display: 'flex', justifyContent: 'center', opacity: '25%', marginTop: '6rem', marginBottom: '6rem'}}>
        <hr style={{width: '50%'}} />
      </div>

      {/* MINI-GAMES */}


      <HomeContainer containerSize='1fr 1fr'>
        <HomeContainerSection>
          <div>
            <h1>3 Minigames</h1>
            <div style={{marginTop: '1.5rem', color: '#b9c0bf'}}>
              {
                supportedMinigames.map((minigame, idx) => {
                  return <h2 key={idx} style={{marginBottom: '1rem'}}>{minigame.minigameName}</h2>
                })
              }
            </div>
            <div style={{ display: 'flex', gap: '2rem', marginTop: '4rem', justifyContent: 'flex-start'}}>
                <Button variant='contained' color='success' size='large' href='/buy'>Start Botting</Button>
                <Button variant='outlined' color='primary' size='large' href='/about'>About Scripts</Button>
            </div>
          </div>
        </HomeContainerSection>
        <HomeContainerSection>
          <ImageFadeUp src={minigamesImage} alt={'minigamesImage'} imgWidth={'30%'} />
        </HomeContainerSection>
      </HomeContainer>

      <div style={{ display: 'flex', justifyContent: 'center', opacity: '25%', marginTop: '6rem', marginBottom: '6rem'}}>
        <hr style={{width: '50%'}} />
      </div>

      {/* ANTIBAN */}

      <HomeContainer containerSize='1fr 1fr'>
        <HomeContainerSection>
          <ImageFadeUp src={antibanImage} alt={'antibanImage'} imgWidth={'30%'} />
        </HomeContainerSection>
        <HomeContainerSection>
          <div>
            <h1>Anti-Ban /</h1>
            <h1>Account Security</h1>
            <div style={{marginTop: '1.5rem', color: '#b9c0bf', display: 'flex', flexDirection: 'column', gap: '1rem'}}>
              {
                antibanMeasures.map((method, idx) => {
                  return <h2 key={idx}>{method.measureDetails}</h2>
                })
              }
            </div>
            <div style={{ display: 'flex', gap: '2rem', marginTop: '4rem', justifyContent: 'flex-start'}}>
                <Button variant='contained' color='success' size='large' href='/buy'>Start Botting</Button>
                <Button variant='outlined' color='primary' size='large' href='/about'>About Security</Button>
            </div>
          </div>
        </HomeContainerSection>
      </HomeContainer>

      <div style={{ display: 'flex', justifyContent: 'center', opacity: '25%', marginTop: '6rem', marginBottom: '6rem'}}>
        <hr style={{width: '50%'}} />
      </div>

    </div>
  )
}

export default LandingPage