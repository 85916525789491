import { Outlet } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import useRefreshTokens from "../../hooks/useRefreshTokens";
import { setUserIsLoading } from "../../redux/slices/Auth.slice";
import useAuth from "../../hooks/useAuth";
import PacmanLoader from 'react-spinners/PacmanLoader'
import { printDebug } from "../../utils/printDebug";
import { BeatLoader } from "react-spinners";

// Refresh the Access Token here so long as the Refresh token is valid - otherwise log out
const PersistLogin = () => {
    const authState = useAuth();
    const accessToken = useSelector(state => state.auth.userAccessToken)
    const isLoading = useSelector(state => state.auth.userIsLoading)
    const dispatch = useDispatch();
    const refresh = useRefreshTokens();
    const isAuthed = localStorage.getItem('isAuthed')

    //TODO - add check to see if refresh token is in the cookies

    // debugger
    // TODO: THIS IS WHERE WE ARE BEING REDIRECTED TO /AUTH BETWEEN REFRESH
    useEffect(() => {
        printDebug(`🔑 [Persist Login] - Rendered with authState: ${JSON.stringify(authState)}`)
        // debugger

        const verifyRefreshToken = async () => {
                dispatch(setUserIsLoading(true))
            try {
                printDebug(`🔑➡ 🆕 [Persist Login] - verifyRefreshToken fired - We must not have had an Access Token in the state.`);
                await refresh();
            } catch (error) {
                printDebug(`🔑 🔁 [Persist Login] - verifyRefreshToken ERROR: ${error}`)
                console.dir(error)
            } finally {
                printDebug(`🔑 ⏮ [Persist Login] - Finally fired`)
                dispatch(setUserIsLoading(false));
            }
        }
        
        if (!authState?.userAccessToken) verifyRefreshToken()
        // !authState?.userAccessToken ? verifyRefreshToken() : dispatch(setUserIsLoading(false))

    }, [])

    useEffect(() => {
        printDebug(`🔑 🔁 [Persist Login] - accessToken update caused re-render`)
    }, [accessToken])

    return (
        <>
         {
             isLoading || isAuthed == 'true' && !authState.userAccessToken
                ? <BeatLoader style={{ position: 'absolute', left: '50%', top: '35%' }} />
                : <Outlet />
         }
        </>
    )
}

export default PersistLogin;