export const UpdatesFeedData = [
  {
    id: 1,
    version: '1.0.0',
    comment: 'Alpha release of DragonPy Client & Web app.',
    details: [
      { text: 'Script Testing', id: 1 },
      { text: 'Cient Testing', id: 2 },
      { text: 'Web Refinement', id: 3 },
    ],
    date: 'Mon May 1 2023',
  },
];
