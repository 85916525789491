export const antibanMeasures = [
  {
    measureDetails: '✔ Zero reported bans',
  },
  {
    measureDetails: '✔ Organic, human-like behavior',
  },
  {
    measureDetails: '✔ Uses official OSRS mobile client',
  },
  {
    measureDetails: '✔ No injection / data manipulation',
  },
  {
    measureDetails: '✔ User specified break-timer',
  },
  {
    measureDetails: '✔ No stored passwords / bank pins',
  },
];
