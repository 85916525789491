import { Button } from '@mui/material';
import React, {useEffect, useState} from 'react'
import { authedApi } from '../../../../api';
import { printDebug } from '../../../../utils/printDebug';

const Success = ({text}) => {
  const [urlSession, setUrlSession] = useState('n/a')

    useEffect(() => {
      const query = new URLSearchParams(window.location.search)
      const sessionParam = query.get('session_id')
      // setUrlSession(sessionParam)
      setUrlSession(sessionParam)
      printDebug(`💳 [Stripe Success ⚙] - Rendered with query: ${query}`)
      printDebug(`💳 [Stripe Success ⚙] - session: ${urlSession}`)
    }, [])

    /**
    * 
    * * Manage Billing Info button for each session_id
    * * For each subscription, store session_id to user doc and return in state to create manage btn for each
    * * session_id sent over in manage sub request to createportalsession which uses session_id to create portalSessionUrl
    * 
    */
    
    const handleManageSubscription = async () => {
      try {
        printDebug(`💳 [Stripe Success ⚙] - handleManageSub fired: ${urlSession}`)

        const payload = {
          session_id: urlSession
        }
        const response = await authedApi.post('/stripe/create-portal-session', payload)

        printDebug(`💳 [Stripe Success ⚙] - response: ${JSON.stringify(response)}`)

        if (response.data.statusCode == '200') {
          // window.location.replace(response.data.payload.portalSessionUrl)
          window.open(response.data.payload.portalSessionUrl)
        } else {
          printDebug(`Stripe Success Response Error: ${response}`)
        }

      } catch (error) {
        printDebug`💳 [Stripe Success ⚙ ERROR]: ${error}`
      }
    }

    return (
        <>
        <div>
          <div className="product Box-root">
            <div className="description Box-root">
              <h3>{text}</h3>
            </div>
            <br />
          </div>
          <form>
            {
              urlSession && 
              <input
              type="hidden"
              id="session-id"
              name="session_id"
              value={urlSession}
              />
            }
            <Button variant='contained' color='primary' onClick={handleManageSubscription}>Manage Subscription</Button>

          </form>
            </div>
        </>
      );
}

export default Success