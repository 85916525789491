export const moneyMakers = [
  {
    methodName: 'Hide Tanner - 1.4M+ / hour (Coming soon)',
  },
  {
    methodName: 'Blast Furnace - 1M+ / hour',
  },
  {
    methodName: 'Unfinished Potions - 250k+ / hour',
  },
  {
    methodName: 'High-Alcher - 225k+ / hour',
  },
  {
    methodName: 'Red Chin Hunter - 350k / hour',
  },
];
