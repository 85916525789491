import styled from 'styled-components';
import { dtMaxSize, tabletMaxSize } from '../../../GlobalStyles';

export const StyledGuidePage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4rem;
  transition: all 1s ease;
`;

export const StyledGuideContentWrapper = styled.div`
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  transition: all 1s ease;
`;

export const StyledGuideContentHeader = styled.h2`
  width: 100vw;
  height: 4vh;
  background: ${props => props.theme.bgPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: all 1s ease;
`;

export const StyledGuideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  margin-top: 4rem;
  transition: all 1s ease;
`;

export const StyledHiddenGuideBar = styled.div`
  position: ${props => (props.isOpen ? 'inline' : 'absolute')};
  top: ${props => (props.isOpen ? '' : '-300px')};
  opacity: ${props => (props.isOpen ? '100%' : '0%')};

  transition: all 1s ease;

  @media (max-width: ${dtMaxSize}) {
    display: none !important;
  }
`;

export const StyledMobileGuideBar = styled.div`
  margin-top: 2rem;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  opacity: ${props => (props.isOpen ? '100%' : '0%')};
  gap: 2rem;
  transition: all 1s ease;

  @media (min-width: ${dtMaxSize}) {
    display: none;
  }
`;
