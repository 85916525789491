export const ReviewData = [
  {
    name: 'anonymous_runite',
    comment:
      "Didn't think I'd be able to finish all my diaries to take advantage of the useful reward perks, simply based on the number of insane level requirements (91 rc'ing, etc.). I pretty much put it out of my mind until I came across DragonPy mobile bot",
    rating: 5,
  },
  {
    name: 'anonymous_chinchompa',
    comment:
      'I was skeptical at first but decided, for the price it was worth a shot. To my surprise everything works fairly well. There was a few issues with a couple of the scripts causing them to stop and the Cow Killer is pretty useless but the support team worked with me to release a number of hot fixes which helped.',
    rating: 3,
  },
  {
    name: 'barrows_boiz',
    comment:
      'Ive used dragonpy mobile bot to level several accounts which I later use in gold farms. Ive trained so many that I no longer wonder if im going to get banned or expect to see it when checking on a suicide account. This bot client brings me back to the golden days of botting when getting banned was rare.',
    rating: 4,
  },
  {
    name: 'duriel123_knockoff',
    comment:
      'Good scripts. Got a bunch of levels really quickly and wasnt banned like I have been on every account ive ever botted on using other scripts/clients. Botting seems to be possible again?',
    rating: 5,
  },
  {
    name: 'no_farming',
    comment:
      'You cant run a farm very easily since the client takes over your mouse and keyboard - you would need to run them on a VM which is capable of virtualization and that can be tricky. I know they are sitll working out the kinks but would have been 5 star if I could run these scripts on a farm because none of my accounts are getting banned suiciding with this client which is really nice.',
    rating: 4,
  },
  {
    name: 'stillnotbanned',
    comment:
      'been suicide botting just out of curiosity. still no ban over a week into my little experiment',
    rating: 4,
  },
];
