import { Button, TextField } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { resetPassword } from '../../../../redux/slices/Auth.slice';
import useAlert from '../../../../hooks/useAlert'
import AuthInput from '../../AuthForm/AuthInput/AuthInput';
import { printDebug } from '../../../../utils/printDebug';

const EmailInput = () => {
  const dispatch = useDispatch();
  const toggleAlert = useAlert();
  const [showPass, setShowPass] = useState(false)
  const [resetForm, setResetForm] = useState({
    pin: null,
    newPass: null,
    confirmNewPass: null,
  })

  const handleShowPass = () => {
    setShowPass(prev => !prev)
  }

  const handleChange = (e) => {
    setResetForm({ ...resetForm, [e.target.name] : e.target.value })
    printDebug(resetForm)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    printDebug(`[EmailInput] - handleSubmit: Fired!`)
    if (resetForm.newPass !== resetForm.confirmNewPass) {
      printDebug(`${resetForm.newPass} - ${resetForm.confirmNewPass}`)
      toggleAlert('Password fields must match.', 'error')
    } else {
      dispatch(resetPassword(resetForm))
    }
  }
  
  return (
    <>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1.25rem'}}>
        <h1>Password Reset</h1>
        <AuthInput name='pin' label='4-Digit Pin' type='text' autoComplete='off' handleChange={handleChange} />
        <AuthInput name='newPass' label='Password' type={showPass ? 'text' : 'password'} autoComplete='on' handleShowPass={handleShowPass} handleChange={handleChange}  />
        <AuthInput name='confirmNewPass' label='Confirm Password' type={showPass ? 'text' : 'password'} autoComplete='on' handleShowPass={handleShowPass} handleChange={handleChange}  />
        
        <Button type='submit' variant='contained'>Submit</Button>
      </form>
    </>
  )
}

export default EmailInput