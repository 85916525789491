import styled from 'styled-components';
import { dtMaxSize } from '../../GlobalStyles';

export const StyledReviewBox = styled.div`
  padding: 2rem 3rem;
  width: 40vw;
  color: ${props => props.theme.bgPrimary};
  background: #33333333;
  border-radius: 1rem;
  gap: 2rem;
  flex-direction: column;
  display: flex;
  max-width: 40rem;
  /* margin-bottom: 2rem; */
  @media (max-width: ${dtMaxSize}) {
    width: 70vw;
  }
`;
