import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { printDebug } from '../utils/printDebug';
import { clearAuthUser, setAccessToken } from '../redux/slices/Auth.slice';
import { setAlert } from '../redux/slices/Alert.slice';

let store;

export const injectStore = _store => {
  store = _store;
};

// API which doesn't require Refresh / Access Tokens
export const unAuthedApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_SERVER_URL,
  withCredentials: true,
  origin: 'http://localhost:3000',
  headers: {
    'Access-Control-Allow-Origin':
      process.env.REACT_APP_ENV === 'production'
        ? process.env.REACT_APP_FRONT_END_URL
        : 'http://localhost:3000',
  },
});

// API which DOES require Refresh / Access Tokens (withCredentials)
export const authedApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_SERVER_URL,
  withCredentials: true,
  origin: 'http://localhost:5000',
  headers: {
    AccessControlAllowOrigin:
      process.env.REACT_APP_ENV === 'production'
        ? process.env.REACT_APP_FRONT_END_URL
        : 'http://localhost:3000',
  },
});

// Add interceptors here for validating Access Token and refreshing if necessary
authedApi.interceptors.request.use(async config => {
  printDebug(`🌐 [API > index > authedApi] - Fired`);
  try {
    // Get the current Access Token out of the state to check if it's expired
    const {
      auth: { userAccessToken: currAT },
    } = store.getState();

    printDebug(`[interceptor] - state AT: ${currAT}`);

    // The following is commented out because it will toggle the setAlert every time refreshAccessToken fails and we want it to be silent in the background
    // if (!currAT) return config;

    const currDate = new Date();
    const decodedToken = jwtDecode(currAT);

    // If the current access token is expired - refresh the token
    if (decodedToken.exp * 1000 < currDate.getTime()) {
      printDebug(`[interceptor] - hitting refreshAccessToken route...`);
      // Get a new access token in the http-only headers and in the response
      // const newAT = await refreshAccessToken();
      const BaseURL = process.env.REACT_APP_BASE_SERVER_URL;

      const response = await axios.get(`${BaseURL}/auth/refreshToken`, {
        withCredentials: true,
      });

      const newAccessToken = response?.data?.accessToken;

      printDebug(`[interceptor] - New Access Token: ${newAccessToken}`);

      // TODO: CHECK IF WE CAN JUST USE setStateFromToken.js HERE
      store.dispatch(setAccessToken(newAccessToken));
    }

    return config;
  } catch (error) {
    printDebug(`This is what the error was: ${error.response.data.statusCode}`);
    if (error.response.data.statusCode == '401') {
      // We tried to refresh our access token but the refresh token was expired and user now needs to re-auth

      printDebug(
        `[interceptor] - Refresh token expired. Attempting to dispatch setAuthFormState(blankAuthState)`
      );

      store.dispatch(
        setAlert({
          isOpen: true,
          message: 'Please re-authenticate',
          severity: 'warning',
        })
      );
      store.dispatch(clearAuthUser());
    }
    return Promise.reject(error);
  }
});
