import React from 'react'
import ImageFadeUp from '../../components/ImageFadeUp/ImageFadeUp'

const ProgressBox = ({img, src, description, name}) => {
  var imageData
  img ? imageData = img : imageData = src

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem', marginTop: '2rem'}}>
        <hr style={{ width: '75%', opacity: '50%'}} />
        <ImageFadeUp img={imageData} imgWidth={'70%'} />
        <p>{description}</p>
        <p>- {name}</p>
    </div>
  )
}

export default ProgressBox