const FAQData = [
  {
    q: 'Can I run multiple instances of DragonPy?',
    a: 'You can run as many instances of DragonPy as you want but keep in mind, DragonPy uses your systems mouse and keyboard so instances are currently limited to one per marchine without the use of VMs which must have virtualization enabled.',
  },
  {
    q: 'Can I use my mouse & keyboard while scripts are running?',
    a: "Currently, DragonPy uses the mouse and keyboard of the system it's running on. As states above, we are giving a free Life-time subscription to the first person to successfully get DragonPy working in a VM.",
  },
  {
    q: 'How safe are DragonPy scripts - Will they get me banned?',
    a: "DragonPy was created around ban evasion with countless, constantly improving measures in place to avoid ultimately wasting your time and money. What's the point of botting if you're not able to keep the progress? That being said, Jagex's detection methods are constantly improving in parallel and we can't account for all the unknowns - we can only do our absolute best, so never bot on an account you're not capable of losing, even in the off chance.",
  },
  {
    q: 'What does "human-like" mean?',
    a: "Human-like, in our context is all of the critical factors we have identified Jagex uses to determine whether an account is operating in a human-manner or programmatically. So, while the mouse movement may not be human-like, it's not a factor which Jagex analyzes (least of all on the mobile client which only recognizes taps) and therefore isn't a priority, at least not for now. Our anti-ban measures are highly specific and tailored to the limitations and capabilities of Jagex's detection methods which we've identified over nearly 20 years of botting & scripting experience.",
  },
  {
    q: "Why won't the script start when I click run?",
    a: 'Sometimes the client is unable to focus BlueStacks App Player if the window is minimized or obstructed somehow - Focus the BlueStacks application by selecting it from the taskbar and try running the script again.',
  },
  {
    q: 'Can I resize the BlueStacks app window?',
    a: 'No - Upon running any of the scripts, the DragonPy client will automatically resize the BlueStacks App Player window to the required size.',
  },
  {
    q: 'Why does the mouse start clicking at seemingly random upon starting a script?',
    a: 'If the BlueStacks App Player window is around on screen or resized after running the script, the relative mouse coordinates will be broken. Simply restart the script and avoid moving the BlueStacks window.',
  },
  {
    q: 'Why does my Virus Scanner suggest an issue (Keylogger, etc.)?',
    a: 'There are functions and packages included in the scripts which monitor keystrokes to determine whether or not to exit the script, etc. - These scripts are 100% safe and include nothing that will compromise your system or accounts.',
  },
];

export default FAQData;
