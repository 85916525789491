import React, {useEffect} from 'react'
import { Button } from '@mui/material'
import ReviewBox from '../../../components/ReviewBox/ReviewBox'
import ProgressBox from '../../../components/ProgressBox/ProgressBox'
import { ReviewData } from './ReviewData'
import sixtyDay from './ProgressReports/day60_account.png'
import seventyDay from './ProgressReports/day70_account.png'
import ninetyDay from './ProgressReports/day90_account.png'
import progressReport1 from './ProgressReports/day100_stats.png'
import progressReport2 from './ProgressReports/progress_reports_2.png'
import { StyledReviewContainer } from './ReviewsPage.styles'


const ReviewsPage = () => {
  const progImages = [progressReport1, progressReport2]
  const progData = [{
    name: 'anonymous_runite',
    description: '2000+ total level in ~14 weeks'
  },{
    name: 'discord_user_#09123',
    description: '1800+ total level and 5 skilling outfits in under 10 weeks'
  },{
    name: 'discord_user_#05548',
    description: 'almost 2K total level with 130M+ exp in 3 months'
  }]
  
  useEffect(() => {

  }, [ReviewData])
  

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '0 4rem', alignItems: 'center', gap: '3rem'}}>
      <h1>Community Reviews</h1>

      <div style={{ display: 'flex', gap: '2rem'}}>
        <Button variant='outlined' href="https://discord.gg/uqyyHSBvqt">View More</Button>
        <Button variant='contained' href="https://discord.gg/uqyyHSBvqt">Submit Review</Button>
      </div>

      <StyledReviewContainer>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column'}}>
          <h3>Progress Reports</h3>
            {
              progImages.map((image, idx) => (
                <ProgressBox key={idx} img={image} name={progData[idx].name} description={progData[idx].description}  />
                ))
              }
        </div>

        <div style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column', gap: '6rem' }}>
          <h3>Feedback</h3>
            {
              ReviewData.map((review, idx) => (
                <ReviewBox key={idx} name={review.name} comment={review.comment} rating={review.rating} />
                ))
              }
        </div>
      </StyledReviewContainer>
    </div>
  )
}

export default ReviewsPage