import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { printDebug } from './utils/printDebug';
import useAuth from './hooks/useAuth';

const Layout = () => {
  const { isLoading } = useAuth();

  useEffect(() => {
    // debugger;
    printDebug(`🌎 [Layout] - Rendered!`);
  }, []);

  // useEffect(() => {
  //   // debugger;
  //   printDebug(`🌎 [Layout] - Rendered!`);
  // }, [isLoading]);

  // debugger;
  return (
    <main>
      <Outlet />
    </main>
  );
};

export default Layout;
