import React from 'react'
import bankExampleImg from '../../../../assets/bank_tab_example.png'
import GuideTemplate from '../GuideTemplate'

const DragonPyGuide = () => {
    return (
        <GuideTemplate header={'DragonPy'}>
            <h3><em>Download</em></h3>
            <p><strong>DragonPy</strong> OSRS mobile bot client can be downloaded and installed once you've <a href="/buy">purchased a license</a>.</p>
            <h3><em>Installation</em></h3>
            <p>Once downloaded, the installation might require clicking 'show more' followed by 'allow' to permit the continuation of the installation process. Your computers anti-virus might flag the software as harmful based on functionality that requires monitoring for a specific key input which ultimately constitutes 'keylogging'. <strong>DragonPy</strong> does not log / store your keystrokes or any data related to your RuneScape account (or otherwise). The keystroke listener is to detect when to quit the program (' End ') or script (' ` ')</p>
            <h3><em>Running</em></h3>
            <p>Once you've installed the <strong>DragonPy</strong> OSRS mobile bot client, you might need to right click ➡ 'run as admin'</p>
            <p>Log in using the email and password you registered on this site with. Nothing will happen if your email / password is incorrect - simply try again.</p>
            <h3><em>Settings (Bank Tab & Break Timer)</em></h3>
            <p>Click the <em>Settings</em> button (bottom left cog icon) to input the bank tab number which the required items for scripts are. We suggest grouping creating a tab and dragging it to the first tab number (1) and storing all your required items in there - see example below:</p>
            <img src={bankExampleImg} alt="botting_bank_tab_example" style={{ minWidth: '20rem', maxWidth: '35vw' }} />
            <p>You can also provide values for the break timer (minutes) to deviate script time. This might break some older scripts as this feature was added in much later (working on full integration). Most scripts also have a built-in relogger which will safely relog a player if the total session time nears 6 hours to avoid being auto-logged but also maintaining the script state to know where to pick up from when logged back in.</p>
            <h3><em>Skill Level Updates</em></h3>
            <p>Finally, in the skills tab, you can select the 'Update' button to provide your OSRS username which is simply used to fetch your skill levels and not stored on any servers. This allows the scripts to make intuitive decisions to maximize your XP in certain cases.</p>
        </GuideTemplate>
    )
  }

export default DragonPyGuide