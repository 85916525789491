import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

// SCREEN SIZES
export const dtMaxSize = '1240px';
export const ltMaxSize = '1025px';
export const tabletMaxSize = '768px';
export const mobileMaxSize = '600px';

// COLOR SCHEMES
export const lightBg = '';
export const lightBg2 = '';
export const lightText = '';
export const lightText2 = '';
// ----
export const darkBg = '#222831';
export const darkBg2 = '#30475E';
export const darkText = '#F05454';
export const darkText2 = '#DDDDDD';

export const lightTheme = {
  bgPrimary: lightBg,
  bgSecondary: lightBg2,
  textPrimary: lightText,
  textSecondary: lightText2,
};
export const darkTheme = {
  bgPrimary: darkBg,
  bgSecondary: darkBg2,
  textPrimary: darkText,
  textSecondary: darkText2,
};

export const GlobalStyles = createGlobalStyle`
        * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Montserrat', sans-serif;
        }

        html{
          overflow-y: overlay;
        }
`;

export const PageContainer = styled.div`
  background-color: ${props => props.theme.bgSecondary};

  z-index: 5;
  /* margin-top: -100px; */
  padding-top: 100px;
  position: relative;
  min-height: 100vh;

  display: ${props => (props.display ? props.display : 'flex')};
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'column'};
  align-items: ${props => (props.ai ? props.jc : 'center')};
`;

export const ContentContainer = styled.div`
  background-color: ${props =>
    props.bg === 'none'
      ? 'rgba(255, 255, 255, 0)'
      : 'rgb(255, 255, 255, 0.25)'};

  width: 100%;
  padding: 5rem;

  display: ${props => (props.display ? props.display : 'flex')};
  justify-content: ${props => (props.jc ? props.jc : 'center')};
  align-items: ${props => (props.ai ? props.ai : 'center')};

  @media only screen and (max-width: ${mobileMaxSize}) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`;

export const AppContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh);
  padding-top: 200px;

  &:last-child {
    justify-content: space-between;
  }
`;
