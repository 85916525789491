import React, { useEffect } from 'react'
import { Alert, Snackbar } from '@mui/material'
import { setAlert } from '../../redux/slices/Alert.slice'
import { useDispatch, useSelector } from 'react-redux'
import { printDebug } from '../../utils/printDebug';

const Alertbar = () => {
    const autoHideMs = 6000
    const dispatch = useDispatch()
    const alertIsOpen = useSelector(state => state.alert.isOpen)
    const alertMessage = useSelector(state => state?.alert.message)
    const alertSeverity = useSelector(state => state?.alert.severity)

    const handleClose = () => {
        dispatch(setAlert({ 'isOpen': false, 'message': '', 'severity': '' }))
    }

    useEffect(() => {
        printDebug(`🚨 [Alertbar] - Rendered! alertIsOpen: ${alertIsOpen}`);
    }, [])
    
    useEffect(() => {
        printDebug(`🚨 [Alertbar] - 'alertIsOpen' triggered a re-render based on value change: ${alertIsOpen}`)
    }, [alertIsOpen])

  return (
    <>
        {
            alertIsOpen && 
            <Snackbar open={alertIsOpen} autoHideDuration={autoHideMs} onClose={handleClose} sx={{ marginTop: '120px'}} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleClose} severity={alertSeverity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        }

    </>
  )
}

export default Alertbar