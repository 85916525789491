import React from 'react'

const PageNotFound = () => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem'}}>
      <h2>Oops!</h2>
      <h3> <em>The page you're trying to reach doesn't exist</em> </h3>
    </div>
  )
}

export default PageNotFound