import React, { useState } from 'react';
import axios from 'axios';
import { authedApi } from '../../api';

const SoftwareUpload = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('exe', file);

    try {
      const response = await authedApi.post('/product/upload', formData);
      alert(`File uploaded successfully with response: ${response}`);
      window.open(response.data.payload)
    } catch (err) {
      console.error(err);
      alert('Error uploading file.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="exe">Select .exe file:</label>
      <input type="file" id="exe" name="exe" accept=".exe" onChange={handleFileChange} />
      <button type="submit">Upload</button>
    </form>
  );
};

export default SoftwareUpload;
