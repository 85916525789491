import React from 'react'
import FAQData from './FAQData'
import discordBtn from '../../../assets/discord_btn.png'

const AboutPage = () => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    
      {/* DISCORD SERVER INVITE */}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '.7rem'}}>
        <a href="https://discord.gg/uqyyHSBvqt" target='_blank'>
        <img src={discordBtn} alt="join_discord_button" style={{ width: '15rem'}} />
        </a>
        <em style={{ opacity: '50%'}}>(looking forward to meeting you!)</em>
        </div>
      </div>

      <div style={{width: '65vw'}}>
        <h1>What is DragonPy</h1>
        <br />
        <p>DragonPy OSRS Mobile Bot Client is the most secure, and reliable way to build any type of account on OSRS; from mains to pures or skillers, all while skipping the gruelling grind.</p>
        <br />
        <p>DragonPy is a collection of mobile bot scripts originally written for personal fun, which automate mundane, repetitive, and arguably unhealthy actions for the massive online multiplayer role-playing game, Old School RuneScape. </p>
        <br />
        <p>DragonPy OSRS mobile bot scripts use the official Old School RuneScape mobile client installed on the <a href="https://www.bluestacks.com/download.html" style={{ color: 'blue', textDecoration: 'none', fontWeight: 600}}>BlueStacks 5</a>  mobile Android emulator for Windows, applying optical character recognition, variable image matching, cartesian coordinates and much more to carry out countless highly specific tasks, progressing your character and unlocking the enjoyable content while you spend your time more productively.</p>
        
        {/* <br /> */}
        <hr style={{ margin: '2rem 0', opacity: '25%'}} />

        <h1>DragonPy Requirements</h1>
        <br />
        <ol style={{lineHeight: '1.5rem'}}>
          <li>BlueStacks 5 (Android mobile emulator)</li>
          <ul style={{ marginLeft: '2vw', marginBottom: '1vh'}}>
            <li><strong>OS:</strong> <em>Windows 10+</em></li>
            <li><strong>RAM:</strong> <em>4GB+</em></li>
            <li><strong>Storage:</strong> <em>5GB+</em></li>
            <li><strong>Graphics:</strong> <em>Standalone (newer) card</em></li>
          </ul>
          <li>Oldschool RuneScape app (installed on emulator from Google play store)</li>
          <ul style={{ marginLeft: '2vw', marginBottom: '1vh'}}>
            <li>Google Account</li>
          </ul>
        </ol>
       
        <br />
        <hr style={{ margin: '2rem 0', opacity: '25%'}} />


        <h1>FAQs</h1>
        <br />
        <ol>
          
          {
            FAQData.map((data, index) => (
              <li style={{ marginBottom: '1.5rem'}} key={index}>
                {/* Question */}
                <strong>{data.q}</strong>
                <br />
                {/* Answer */}
                <em>{data.a}</em>
              </li>
            ))
          }

        </ol>
      </div>
    </div>
  )
}

export default AboutPage