import React from 'react'

const Message = ({message}) => {
  return (
    <div>
      <h3>{message}</h3>
    </div>
  )
}

export default Message