import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {
  AppContentWrapper,
  darkTheme,
  lightTheme,
  PageContainer,
} from './GlobalStyles';
import Alertbar from './components/Alertbar/Alertbar';
import Navbar from './components/Navbar/Navbar';
import Layout from './Layout';
import AboutPage from './pages/UnAuthed/About/AboutPage';
import AuthPage from './pages/UnAuthed/AuthForm/AuthPage';
import ContactPage from './pages/UnAuthed/Contact/ContactPage';
import LandingPage from './pages/UnAuthed/Landing/LandingPage';
import PageNotFound from './pages/UnAuthed/NotFound/404';
import RequireAuth from './components/RequireAuth/RequireAuth';
import useAuth from './hooks/useAuth';
import Unauthorized from './pages/Authed/Unauthorized/Unauthorized';
import VerifyEmail from './pages/Authed/VerifyEmail/VerifyEmail';
import PersistLogin from './components/PersistLogin.js/PersistLogin';
import RequireEmail from './components/RequireEmail/RequireEmail';
import ResetPass from './pages/UnAuthed/ResetPass/ResetPass';
import AdminDashboard from './pages/Authed/AdminDashboard/AdminDashboard';
import { printDebug } from './utils/printDebug';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Stripe from './pages/CheckOut/Stripe/Stripe';
import BuyPage from './pages/UnAuthed/Buy/BuyPage';
import SetupPage from './pages/UnAuthed/Setup/SetupPage';
import ReviewsPage from './pages/UnAuthed/Reviews/ReviewsPage';
import AccountPage from './pages/Authed/Account/AccountPage';
import Home from './pages/Authed/Home/Home';
import Footer from './components/Footer/Footer';
import InstallPage from './pages/Authed/Install/InstallPage';
import TosPage from './pages/UnAuthed/ToS/TosPage';
import Guides from './pages/Authed/Guides/Guides';

function App() {
  const [theme, setTheme] = useState('dark');
  const stripeApiKey =
    process.env.REACT_APP_STRIPE_MODE === 'live'
      ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV;

  console.log(`REACT_APP_STRIPE_MODE: ${process.env.REACT_APP_STRIPE_MODE}`);
  const stripePromise = loadStripe(stripeApiKey);

  useEffect(() => {
    printDebug('App.js rendered');
  }, []);

  return (
    <>
      <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <Elements stripe={stripePromise}>
          <Navbar />
          <AppContentWrapper>
            <Alertbar />
            <Routes>
              <Route path='/' element={<Layout />}>
                <Route element={<PersistLogin />}>
                  {/* UNAUTHED */}
                  <Route path='/' element={<LandingPage />} />
                  <Route path='/about' element={<AboutPage />} />
                  <Route path='/tos' element={<TosPage />} />
                  <Route path='/buy' element={<BuyPage />} />
                  <Route path='/contact' element={<ContactPage />} />
                  <Route path='/reviews' element={<ReviewsPage />} />
                  <Route path='/setup' element={<SetupPage />} />
                  <Route path='/auth' element={<AuthPage />} />
                  <Route path='/unauthorized' element={<Unauthorized />} />
                  <Route path='/resetPassword/*' element={<ResetPass />} />
                  {/* AUTHED */}
                  <Route element={<RequireAuth permittedRoles={[2001]} />}>
                    <Route path='/verifyEmail' element={<VerifyEmail />} />
                    <Route element={<RequireEmail />}>
                      <Route path='/home' element={<Home />} />
                      <Route path='/guides/*' element={<Guides />} />
                      <Route path='/install' element={<InstallPage />} />
                      <Route path='/account' element={<AccountPage />} />
                      <Route path='/checkout/*' element={<Stripe />} />
                    </Route>
                  </Route>
                  {/* AUTHED PROTECTED */}
                  <Route element={<RequireAuth permittedRoles={[69420]} />}>
                    <Route element={<RequireEmail />}>
                      <Route
                        path='/adminDashboard'
                        element={<AdminDashboard />}
                      />
                    </Route>
                  </Route>
                  {/* Catch all - 404 Not-Found */}
                  <Route path='*' element={<PageNotFound />} />
                </Route>
              </Route>
            </Routes>
            <Footer />
          </AppContentWrapper>
        </Elements>
      </ThemeProvider>
    </>
  );
}

export default App;
