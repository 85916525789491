import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import { handleModalClose } from '../../../../redux/slices/AdminDash.slice';
import { printDebug } from '../../../../utils/printDebug';
import { useState } from 'react';
import { authedApi } from '../../../../api';
import { setAlert } from '../../../../redux/slices/Alert.slice';
import LicenseExpiration from './LicExpDays/LicExpDays';
import useAlert from '../../../../hooks/useAlert';
import DropDown from '../../../../components/DropDown/DropDown';
import MenuItem from '@mui/material/MenuItem';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddLicenseModal = () => {
    const dispatch = useDispatch()
    const handleClose = () => dispatch(handleModalClose())
    const selUsers = useSelector(state => state.adminDash.selectedUsers)
    const toggleAlert = useAlert()

    const categoryMenuItems = [
      {
        field: 'category',
        label: 'Category',
        options: [
          'all_access'
        ]
      }
    ]

    const menuItems = [
      {
        value: 'all_access',
        label: 'All Access'
      },
      {
        value: 'package',
        label: 'Package',
      },
      {
        value: 'individual',
        label: 'Individual'
      }
    ]

    const [expDays, setExpDays] = useState(1)
    // const [dateVal, setDateVal] = useState(moment().add(1, 'days'));
    const [payloadData, setPayloadData] = useState({
      users: selUsers,
      category: null,
      sub: null,
      name: null,
      expSec: 86400,
    })

    const isModalOpen = useSelector(state => state.adminDash.isModalOpen)

    useEffect(() => {
      printDebug(`Selected Users in License Modal = ${selUsers}`);
      setPayloadData({ ...payloadData, users: selUsers })
    }, [selUsers])

    useEffect(() => {
      printDebug(`Modal re-rendering due to open state change: ${isModalOpen}`);
    }, [isModalOpen])

    const handleInput = (e) => {
      setPayloadData({... payloadData, [e.target.name]: e.target.value})
    }

    // const handleDateChange = (newValue) => {
    //   setDateVal(newValue);
    //   printDebug(`handleDateChange newVal: ${newValue}`);
    // };

    const incDays = () => {
      setExpDays(expCount => expCount+=1)
      // multiply the number of days time the number of seconds in a day
      const expSec = expDays * 86400
      setPayloadData({... payloadData, expSec })
      printDebug(`Expiration seconds (inc): ${payloadData.expSec}`);
    }
    
    const decDays = () => {
      if (expDays === 1){
        printDebug(`Can't set negative expiration (expDays == ${expDays})`)
        return
      }
      setExpDays(expCount => expCount-=1)
      const expSec = expDays * 86400
      setPayloadData({... payloadData, expSec })
      printDebug(`Expiration seconds (dec): ${payloadData.expSec}`);
    }

    const handleAddLicense = async () => {
      printDebug(`HandleAddLicense fired - isModalOpen: ${isModalOpen}`);

      printDebug(`Payload data: ${JSON.stringify(payloadData)}`)

      const userNullOrEmpty = payloadData.users == null || payloadData.users == ""
      const subNullOrEmpty = payloadData.sub == null || payloadData.sub == ""
      const categoryNullOrEmpty = payloadData.category == null || payloadData.category == ""
      const expSecNull = payloadData.expSec == null || payloadData.expSec == ""

      // if (expSecNull) {
      //   printDebug(`expSecNull = True | Setting PayloadData.expSec`);
      //   const expSec = 86400
      //   setPayloadData({...payloadData, expSec })
      // }

      if (userNullOrEmpty || subNullOrEmpty || categoryNullOrEmpty || expSecNull) {
        printDebug(`User, Sub, or Category is null!`);
        toggleAlert('Provide Category (AllAccess) & Subscription (Monthly)', 'error')
        return
      }

      const response = await authedApi.post('/users/addLicense', payloadData)

      printDebug(`Response: ${JSON.stringify(response)}`)

      if (response.data.statusCode == 200) {
        dispatch(setAlert({ isOpen: true, message: response.data?.message, severity: 'success' }))
      } else {
        dispatch(setAlert({ isOpen: true, message: response.data?.errorMessage, severity: 'error' }))
      }

      handleClose()
    }

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2" style={{paddingBottom: '1rem'}}>
            Add License to:
          </Typography>
              {
                  selUsers.map(user => (
                      <p key={user} style={{ marginBottom: '.5rem'}}><em>{user}</em></p>
                  ))
              }
          <Typography variant='h5' id="modal-modal-description" sx={{ mt: 5 }}>
            License Payload:
          </Typography>
          <Box component='form' style={{marginTop: '2rem', padding: '1rem 0', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%', alignItems: 'center'}}>
                {/* <DropDown dropDownTitle={'Category'} menuItemsArr={menuItems} />
                <DropDown dropDownTitle={'Subscription'} />
                <DropDown dropDownTitle={'Name'} /> */}
                <TextField
                 required
                 id='category'
                 name='category'
                 label='Category'
                 onChange={handleInput}
                /> 

                <TextField
                 required
                 id='subscription'
                 name='sub'
                 label='Subscription'
                 onChange={handleInput}
                />
                <TextField
                 id='name'
                 name='name'
                 label='Name'
                 onChange={handleInput}
                />
                
                <LicenseExpiration count={expDays} incCount={incDays} decCount={decDays}  />

                <Button variant='contained' style={{marginTop: '1rem'}} onClick={handleAddLicense}>
                    + Add License
                </Button>
            </div>

          </Box>



        </Box>
      </Modal>
    </div>
  );
}

export default AddLicenseModal