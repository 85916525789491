import React, { useEffect } from 'react'
import { StyledNavLink, NavContainer, NavSection, NavSpan1, NavSpan2, StyledRightNav, StyledNavWrapper } from './Navbar.styles'
import NavLinks from './navLinks/NavLinks'
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { logoutUser } from '../../redux/slices/Auth.slice';
import { Link, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { printDebug } from '../../utils/printDebug';
import MobileMenu from './MobileMenu/MobileMenu';

const Navbar = () => {
    const userId = useSelector(state => state.auth.userId)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        printDebug(`[Navbar] - handleLogout firing dispatch(logoutUser())...`)
        dispatch(logoutUser())
        navigate('/auth')
    }

    useEffect(() => {

    }, [userId])

  return (
    <NavContainer>
        <StyledNavWrapper>
            <NavSection className='brand-logo'>
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <NavSpan1>Dragon</NavSpan1><NavSpan2>Py</NavSpan2>
                </Link>
            </NavSection>
            <NavSection pos='mid' className='mid'>
                <NavLinks />
            </NavSection>
            <NavSection className='right'>
                <StyledRightNav>
                    {
                    userId
                    ?   (
                            <>
                                <a href="/account" style={{ marginRight: '2rem', display: 'flex', justifyContent: 'center'}}>
                                    <AccountCircleIcon fontSize='large' style={{ color: 'white' }}/>
                                </a>
                                <Button variant='contained' onClick={handleLogout}>
                                    Logout
                                </Button>
                            </>
                            )
                            : (
                            <StyledNavLink to='/auth' className='sign-in'>
                                <PersonIcon style={{ fontSize: '2.25rem'}} />
                                <p className='sign-in-text'>Login | Register</p>
                            </StyledNavLink>
                        )
                    }
                    </StyledRightNav>

                    <MobileMenu />
                {/* Responsive mobile menu goes here */}
            </NavSection>
        </StyledNavWrapper>
    </NavContainer>
  )
}

export default Navbar