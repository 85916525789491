import React from "react";
import styled, { keyframes } from "styled-components";

const grow = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
`;

const StyledText = styled.span`
  display: inline-block;
  font-size: 1.12rem;
  /* font-weight: bold; */
  letter-spacing: 0.1em;
  transition: transform 0.2s ease-in-out;
  &:hover {
    animation: ${grow} 0.2s forwards;
  }
`;

const AnimatedText = ({ text, elementType='span'}) => {
  const Element = elementType;
  return (
    <Element>
      {text.split("").map((letter, i) => (
        <StyledText key={i}>{letter}</StyledText>
      ))}
    </Element>
  );
};

export default AnimatedText;
