import React from 'react'
import SoftwareUpload from '../../../components/SoftwareUpload/SoftwareUpload'
import UserTable from './UserTable/UserTable'

const AdminDashboard = () => {
  return (
      <div  style={{ padding: '3rem 5rem'}}>
      <div>AdminDashboard</div>
      <UserTable />
      <SoftwareUpload />
      </div>
  )
}

export default AdminDashboard