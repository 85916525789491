import styled from 'styled-components';
import { dtMaxSize } from '../../../GlobalStyles';

export const StyledMobileMenu = styled.div`
  display: none;

  @media only screen and (max-width: ${dtMaxSize}) {
    display: inline;
  }
`;

export const StyledBurger = styled.div`
  position: relative;
  z-index: 1000 !important;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .top {
    rotate: ${props => (props.isOpen ? '0deg' : '140deg')};
    transform: ${props => !props.isOpen && 'translateY(-15px)'};
    transition: all 0.3s ease-in-out;
    background-color: ${props =>
      props.isOpen
        ? props.theme.textSecondary
        : props.theme.bgSecondary} !important;
  }

  .middle {
    opacity: ${props => (props.isOpen ? '100%' : '0%')} !important;
    transition: all 0.3s ease-in-out;
  }

  .bottom {
    transition: all 0.3s ease-in-out;
    transform: ${props => !props.isOpen && 'translateY(15px)'};
    rotate: ${props => (props.isOpen ? 'deg' : '-140deg')};
    background-color: ${props =>
      props.isOpen
        ? props.theme.textSecondary
        : props.theme.bgSecondary} !important;
  }
`;

export const StyledMenu = styled.div`
  position: absolute;
  height: 100vh;
  width: 60vw;
  right: ${props => (props.isOpen ? '-60vw' : 0)};
  opacity: 98%;
  /* backdrop-filter: blur(5px); */

  top: 95px;
  /* top: 0; */
  z-index: 999 !important;
  background-color: ${props => props.theme.bgSecondary};
  transition: all 0.5s ease;
`;
