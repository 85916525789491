import React from 'react'

const TosPage = () => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', alignItems: 'center'}}>
        <h2>Terms of Service</h2>
        <ol style={{ lineHeight: '2rem', padding: '0 4rem'}}> 
          <li>You do not have the right to re-sell this software or provide it to anyone else.</li>
          <li>Attempting to reverse engineer the compiled source-code is prohibited and under protection of intellectual property rights.</li>
          <li>We are not responsible for any bans your account may receive during or after use of any automation software.</li>
          <li>While subscriptions may be cancelled - payments are final. If you have an issue, join the Discord and submit a ticket.</li>
        </ol>
      </div>
    </div>
  )
}

export default TosPage