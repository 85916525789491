import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../../../../redux/slices/Auth.slice'
import { printDebug } from '../../../../utils/printDebug'
import AnimatedText from '../../../LetterHover/LetterHover'
import linkData from '../../navLinks/linkData'
import { StyledMobileNav, StyledMobileNavLink } from './MobileNavLinks.styles'

const MobileNavLinks = ({toggleMenuOpen}) => {
    const userId = useSelector(state => state.auth.userId)
    const currPath = window.location.pathname
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLogout = () => {
        printDebug(`[Navbar] - handleLogout firing dispatch(logoutUser())...`)
        dispatch(logoutUser())
        navigate('/auth')
        toggleMenuOpen()
    }
  
    useEffect(() => {
      printDebug(`currPath: ${currPath}`)
  
    }, [currPath, userId])

  return (
    <StyledMobileNav>
        <nav>
            {
            linkData.map((item, index) => {
                if (item.protected && userId)
                {
                    return (
                    <StyledMobileNavLink className={ currPath == item.path ? 'active' : 'inactive'}  key={index} to={item.path} onClick={toggleMenuOpen} >
                        <AnimatedText text={item.text} />
                        {/* {item.text} */}
                    </StyledMobileNavLink>
                    )
                }
                else if (!item.protected) {
                    return (
                    <StyledMobileNavLink className={ currPath == item.path ? 'active' : 'inactive'}  key={index} to={item.path} onClick={toggleMenuOpen} >
                        <AnimatedText text={item.text} />
                        {/* {item.text} */}
                    </StyledMobileNavLink>
                    )
                }
            })
            }
            {
                userId && 
                    <StyledMobileNavLink className={ currPath == '/account' ? 'active' : 'inactive'} to='/account' onClick={toggleMenuOpen}>
                        <AnimatedText text='Account' />
                    </StyledMobileNavLink>
            }

        </nav>
        {
            userId ? 
                <Button variant='contained' onClick={handleLogout} style={{ width: '10rem', marginTop: '3rem'}} onClick={toggleMenuOpen}>
                Logout
                </Button>
                :
                <>
                <Button variant='contained' href='/auth' style={{ width: '10rem', marginTop: '3rem'}} onClick={toggleMenuOpen}>
                    Login
                </Button>
                <p style={{ marginTop: '1rem', opacity: '50%', fontWeight: '300' }}>
                    <a style={{ color: 'white' }} href="/auth"><em>Register</em></a>
                </p>
                </>
        }

    </StyledMobileNav>
  )
}

export default MobileNavLinks