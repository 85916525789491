import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Success from '../../pages/CheckOut/Stripe/Success/Success'
import { printDebug } from '../../utils/printDebug'
import { authedApi } from '../../api'
import { StyledSubStatus } from './LicenseStatus.styles'

const LicenseStatus = () => {
  const stripePurchases = useSelector(state => state.auth?.userStripe?.purchases)
  const [subStatus, setSubStatus] = useState('')

  const handleManageSubscription = async (e) => {
    try {
      printDebug(`[SubStatus] - handleManageSub fired: ${e.currentTarget.value}`)

      const payload = {
        customer_id: e.currentTarget.value
      }

      const response = await  authedApi.post('/stripe/create-portal-session', payload)

      printDebug(`[SubStatus] - response: ${JSON.stringify(response)}`)

      if (response.data.statusCode == '200') {
        // window.location.replace(response.data.payload.portalSessionUrl)
        window.open(response.data.payload.portalSessionUrl)
      } else {
        printDebug(`Stripe Success Response Error: ${response}`)
      }

    } catch (error) {
      printDebug`[SubStatus] ERROR: ${error}`
    }
  }

  const calcRenewDateFromPurchaseDate = (purchaseDate, purchaseTerm) => {
    printDebug(`Attempting calculation of purchaseDate: ${purchaseDate} and term: ${purchaseTerm}`)
    try {
      let addDays
      let buyDate = new Date(purchaseDate)
      if (purchaseTerm === 'monthly') {
        addDays = 30
      }
      if (purchaseTerm === '3-month') {
        addDays = 90
      }
      if (purchaseTerm === 'annual') {
        addDays = 365
      } 
      buyDate.setDate(buyDate.getDate()+addDays)
      const formattedDate = buyDate.toLocaleDateString()
      printDebug(`formattedDate: ${formattedDate}`);
      return formattedDate
    } catch (error) {
      printDebug(`[SubStatus] - calcRenewDateFromPurchaseDate - ERROR: ${error}`)
      return
    }
  }

  useEffect(() => {
    printDebug(`[SubStatus] - authedUser stripePurchases: ${stripePurchases}`)
  })

  useEffect(() => {
    printDebug(`[SubStatus] - authedUser stripePurchases: ${stripePurchases}`)
    stripePurchases.length > 0 ? setSubStatus('🟢') : setSubStatus('🔴')
  }, [stripePurchases])

  return (
      <>
        <StyledSubStatus>
          <h2>License Status {subStatus}</h2>
          <div style={{display: 'flex', flexDirection: 'column', gap: '2rem', marginTop: '3rem'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', gap: '3rem', fontWeight: '600'}}>
              <p>Type</p>
              <p>Term</p>
              {/* <p>Customer_id</p> */}
              <p>Renew Date</p>
              <p>Action</p>
            </div>
            {
              stripePurchases != null && stripePurchases != '' &&
                stripePurchases.map((purchase, idx) => {
                  return (
                    <div key={idx} style={{display: 'flex', justifyContent: 'space-between', gap: '4rem'}}>
                      <p>{purchase.licenseType.charAt(0).toUpperCase() + purchase.licenseType.slice(1)}</p>
                      <p>{purchase.licenseTerm.charAt(0).toUpperCase() + purchase.licenseTerm.slice(1)}</p>
                      {/* {purchase?.customerId ? <p>{purchase?.customerId.slice(1,10)}...</p> : <p>N/A</p> } */}
                      <p>
                      {
                        purchase.licenseTerm.toLowerCase() != 'lifetime' ? calcRenewDateFromPurchaseDate(purchase.purchaseDate, purchase.licenseTerm) : 'N/A'
                      }
                      </p>

                      <Button value={purchase.customerId} disabled={purchase.licenseTerm.toLowerCase() == 'lifetime'} color='primary' variant='outlined' onClick={handleManageSubscription}>Manage</Button>
                    </div>
                    )
                  })
            }
          </div>
          {/* <Success /> */}
        </StyledSubStatus>
      </>
  )
}

export default LicenseStatus