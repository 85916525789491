import React from 'react'
import { StyledGuideContent, StyledGuideContentHeader, StyledGuideContentWrapper } from './Guides.styles'

const GuideTemplate = ({ header, children }) => {
  return (
    <StyledGuideContentWrapper>
        <StyledGuideContentHeader><span style={{color: 'red', marginRight: '1rem'}}>{header}</span> Setup Guide</StyledGuideContentHeader>
        <StyledGuideContent>
          {children}
        </StyledGuideContent>
    </StyledGuideContentWrapper>
  )
}

export default GuideTemplate