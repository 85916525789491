export const StripePrices = {
  monthly: {
    originalPrice: 70,
    actualPrice: 49,
    lookupKey:
      process.env.REACT_APP_STRIPE_MODE === 'live'
        ? 'price_1N2V8tEDR4WumValUXnczvaf'
        : 'price_1Mrce1EDR4WumVal9yn3Z4um',
  },
  triMonthly: {
    originalPrice: 39,
    actualPrice: 32,
    lookupKey:
      process.env.REACT_APP_STRIPE_MODE === 'live'
        ? 'price_1N2V8tEDR4WumValrD6lIm8O'
        : 'price_1MrpMCEDR4WumVal2lnGsAhs',
  },
  annual: {
    originalPrice: 29,
    actualPrice: 17,
    lookupKey:
      process.env.REACT_APP_STRIPE_MODE === 'live'
        ? 'price_1N2V8tEDR4WumValCrLxFUAS'
        : 'price_1MrpMUEDR4WumValAVlTMPAN',
  },
  lifeTime: {
    originalPrice: 350,
    actualPrice: 249,
    lookupKey:
      process.env.REACT_APP_STRIPE_MODE === 'live'
        ? 'price_1N2V8tEDR4WumValmLQMKXvM'
        : 'price_1MrsnMEDR4WumValuBZvjYoY',
  },
};
