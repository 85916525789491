import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { dtMaxSize, tabletMaxSize } from '../../../GlobalStyles';

export const HomeContainer = styled.div`
  /* display: grid; */
  /* grid-template-columns: ${props => props.containerSize}; */
  /* justify-content: center; */
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${dtMaxSize}) {
    flex-direction: column !important;
    gap: 2rem;
  }
`;

export const HomeContainerSection = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  width: 50%;

  @media only screen and (max-width: ${dtMaxSize}) {
  }
`;

export const StyledSkillScriptsContainer = styled.div`
  margin-top: 1.5rem;
  color: #b9c0bf;
  font-size: 1.25rem;
  display: flex;
  justify-content: space-evenly;
  gap: 4rem;

  @media (max-width: ${tabletMaxSize}) {
    flex-direction: column;
  }
`;
