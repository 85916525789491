import { authedApi, unAuthedApi } from '..';
import { printDebug } from '../../utils/printDebug';

export const registerUser = formData => {
  return new Promise(async (resolve, reject) => {
    printDebug(
      `[Auth API] - registerUser: Calling back-end /auth/register with formData`
    );
    try {
      const response = await unAuthedApi.post('/auth/register', formData);

      printDebug(
        `[Auth API] - registerUser: back with response: ${JSON.stringify(
          response
        )}`
      );

      resolve(response);
    } catch (error) {
      console.error(`[Auth API] - registerUser: ERROR: ${error}`);
      reject(error);
    }
  });
};

export const loginUser = formData => {
  return new Promise(async (resolve, reject) => {
    printDebug(
      `[Auth API] - Login: Calling back-end /auth/login with formData`
    );

    try {
      const response = await unAuthedApi.post('/auth/login', formData);

      printDebug(
        `[Auth API] - Back from auth/login with response: ${response}`
      );

      resolve(response);
    } catch (error) {
      printDebug(`[Auth API] - Login ERROR: ${error}`);
      reject(error);
    }
  });
};

export const socialSignIn = socialUser => {
  return new Promise(async (resolve, reject) => {
    printDebug(
      `[Auth API] - socialSignIn fired with social user: ${JSON.stringify(
        socialUser
      )}`
    );

    try {
      const response = await unAuthedApi.post('/auth/googleAuth', socialUser);

      printDebug(
        `[Auth API] - Back from /auth/googleAuth with response: ${response}`
      );

      resolve(response);
    } catch (error) {
      printDebug(`[Auth API] - socialSignIn Error: ${error}`);
      reject(error);
    }
  });
};

export const refreshAccessToken = _ => {
  return new Promise(async (resolve, reject) => {
    try {
      printDebug(`[Auth API] - refreshAccessToken fired...`);

      const response = await unAuthedApi.get('/auth/refreshToken');

      resolve(response);
    } catch (error) {
      printDebug(`❌ [Auth API] - refreshAccessToken ERROR: ${error}`);
      reject(error);
    }
  });
};

export const logoutUser = () => {
  return new Promise(async (resolve, reject) => {
    printDebug(`[Auth API] - Logout: calling server /auth/logout`);

    try {
      const response = await unAuthedApi.get('/auth/logout');

      printDebug(
        `[Auth API] - Successfully came back from server after logout`
      );

      resolve(response);
    } catch (error) {
      printDebug(`[Auth API] - logoutUser ERROR: ${error}`);
      reject(error);
    }
  });
};

export const requestReset = email => {
  return new Promise(async (resolve, reject) => {
    printDebug(`[Auth API] - requestRecovery() calling server /auth/recover`);
    try {
      const response = await unAuthedApi.post('/auth/recover', email);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const validateRecoveryUser = () => {
  return new Promise(async (resolve, reject) => {
    printDebug(
      `[Auth API] - validateRecoveryUser: fired! calling /auth/validateResetUser/:id/:resetToken`
    );
    try {
      let exactPath = window.location.pathname;
      exactPath = exactPath.replace('resetPassword', 'auth/validateResetUser');
      const response = await unAuthedApi.get(exactPath);
      printDebug(
        `[Auth API] - validateRecoveryUser: back with response = ${JSON.stringify(
          response
        )}`
      );
      resolve(response);
    } catch (error) {
      printDebug(`[Auth API] - validateRecoveryUser: ERROR: ${error}`);
      reject(error);
    }
  });
};

export const resetPassword = resetPwForm => {
  return new Promise(async (resolve, reject) => {
    try {
      printDebug(`[Auth API] - resetPassword: Fired!`);
      let exactPath = window.location.pathname;
      // exactPath = exactPath.replace('resetPassword', 'validateResetUser')
      const resetPwUri =
        process.env.REACT_APP_BASE_SERVER_URL + '/auth' + exactPath;

      printDebug(`[Auth API] - resetPwForm: Hitting endpoint @ ${resetPwUri}`);
      const response = await unAuthedApi.post(resetPwUri, resetPwForm);
      printDebug(
        `[Auth API] - resetPassword: back with response = ${JSON.stringify(
          response
        )}`
      );
      resolve(response);
    } catch (error) {
      printDebug(`[Auth API] - resetPassword: ERROR ${error}`);
      reject(error);
    }
  });
};
