import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledMobileNav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledMobileNavLink = styled(Link)`
  display: flex;
  flex-direction: column;
  color: white;
  padding: 1.25rem 2rem 0;
  /* padding: 1.5rem 2rem; */
  margin-top: 1rem;
  font-weight: 600;

  &.active {
    color: ${props => props.theme.textPrimary} !important;
  }
`;
