export function createData(email, first, last, numLicenses, joinDate) {
    return {
      email,
      first,
      last,
      numLicenses,
      joinDate,
    };
  }
  
  export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  export function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  export const headCells = [
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email',
    },
    {
      id: 'joinDate',
      numeric: false,
      disablePadding: false,
      label: 'Join Date',
    },
    {
      id: 'first',
      numeric: false,
      disablePadding: false,
      label: 'First',
    },
    {
      id: 'last',
      numeric: false,
      disablePadding: false,
      label: 'Last',
    },
    {
      id: 'numLicenses',
      numeric: true,
      disablePadding: false,
      label: 'Licenses',
    },
  
  ];


  