import React from 'react'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography } from '@mui/material'
import { StyledAvatar } from '../AuthPage.styles';

const AuthHeader = ({authState}) => {
  return (
    <>
        <StyledAvatar >
          <LockOutlinedIcon />
        </StyledAvatar>
        <Typography variant='h5'>
          { authState === 'login' ? ('Log In') : authState === 'register' ? ('Register') : ('Recover Account')}
        </Typography>
        <hr style={{ width: '36%', opacity: '32%'}}/>
    </>
  )
}

export default AuthHeader