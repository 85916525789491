import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { PacmanLoader } from 'react-spinners'
import { BarLoader } from 'react-spinners'
import { printDebug } from '../../../utils/printDebug'
import { ContentContainer } from '../../../GlobalStyles'
import { validateRecoveryUser } from '../../../redux/slices/Auth.slice'
import useAuth from '../../../hooks/useAuth'
import EmailInput from './EmailInput/EmailInput'

// in the useEffect() - dispatch a check to see if the id in the req.param is a valid user and if the hashed token is expired
// If not valid user - navigate to auth page
// If Valid user - Expired token: Navigate to auth page
// If valid user - valid token: show content


const ResetPass =  () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isValid, setIsValid] = useState(false)
    const [checked, setChecked] = useState(false)
    const {userIsLoading, userAccessToken} = useAuth();

    const validateUser = async () => {
        try {
            const response = await dispatch(validateRecoveryUser())
            setIsValid(response?.payload)
            setChecked(true)
            return response.payload;
        } catch (error) {
            printDebug(`ERROR: ${error}`)
            setIsValid(false)
            setChecked(true)
        }
        return;
    }

    useEffect(() => {
        printDebug(`[ResetPass Page] - UseEffect: Rendered!`)
        if (!userAccessToken) {
            validateUser();
        } else {
            navigate('/home')
        }

    }, [])

    useEffect(() => {
        if (userAccessToken) navigate('/home')
    }, [userAccessToken])

  return (
    <>
        <ContentContainer>
        {
            userIsLoading || !checked
                ? <BarLoader style={{ position: 'absolute', left: '50%', top: '50%' }} />
                : isValid
                    ? (<>
                        <EmailInput />
                    </>)
                    : <Navigate to='/auth' />
            }
        </ContentContainer>
    </>
  )
}

export default ResetPass