import React, {useEffect, useState} from 'react'
import { printDebug } from '../../../utils/printDebug';
import { StyledGuidePage, StyledHiddenGuideBar, StyledMobileGuideBar } from './Guides.styles';
import { StyledLinkTileWrapper } from '../Install/InstallPage.styles';
import GuideLinkTile from '../../../components/LinkTile/GuideLinkTile';
import img1 from '../../../assets/client_home.png'
import img2 from '../../../assets/displaySettings.png'
import img3 from '../../../assets/osrsApp.png'
import img4 from '../../../assets/skills_image.png'
import DragonPyGuide from './Guides/DragonPyGuide';
import BlueStacksGuide from './Guides/BlueStacksGuide';
import OSRSGuide from './Guides/OSRSGuide';
import ScriptGuides from './Guides/ScriptGuides';

const Guides = () => {
  const [currGuide, setCurrGuide] = useState('')
  const [isGuideMenuOpen, setIsGuideMenuOpen] = useState(true)

    useEffect(() => {
      const query = new URLSearchParams(window.location.search);
      setCurrGuide(query.get('guide-name'))
      if (currGuide != '' && currGuide != null) {
        setIsGuideMenuOpen(false)
      } else {
        setIsGuideMenuOpen(true)
      }

    }, [currGuide])


    const RenderGuide = () => {
      printDebug(`Rendering value: ${currGuide}`);
      switch (currGuide) {
        case 'dragonpy-setup':
          return DragonPyGuide()
        case 'bluestacks-setup':
          return BlueStacksGuide()
        case 'osrs-client-setup':
          return OSRSGuide()
        case 'scripts':
          return ScriptGuides()
        default:
          break;
      }
    }

  return (
    <StyledGuidePage>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <h2>Guides</h2>
        <p onClick={() => setIsGuideMenuOpen(prev => !prev)} style={{ fontSize: '1.4rem', cursor: 'pointer' }}>{isGuideMenuOpen ? '🔽' : '🔼'}</p>
      </div>
      <StyledHiddenGuideBar isOpen={isGuideMenuOpen}>
        <StyledLinkTileWrapper>
            <GuideLinkTile linkPath='dragonpy-setup' img={img1} title={'DragonPy Setup'} description={'Configure your DragonPy OSRS mobile bot client to use the scripts.'} hoverText='Setup DragonPy' />     
            <GuideLinkTile linkPath='bluestacks-setup' img={img2} title={'BlueStacks Setup'} description={'Configure the BlueStacks 5 mobile emulator to use DragonPy.'} hoverText='Setup BlueStacks mobile emulator' />     
            <GuideLinkTile linkPath='osrs-client-setup' img={img3} title={'OSRS Setup'} description={'Configure the OSRS mobile app in the emulator to use the DragonPy client.'} hoverText='Setup OSRS client'/>     
            <GuideLinkTile linkPath='scripts' img={img4} title={'Script Guides'} description={'Read more details about individual scripts.'} hoverText='Learn how to use scripts' />     
        </StyledLinkTileWrapper>
      </StyledHiddenGuideBar>
      
      <StyledMobileGuideBar isOpen={isGuideMenuOpen}>
        <a href={`${process.env.REACT_APP_FRONT_END_URL}/guides?guide-name=dragonpy-setup`}>DragonPy Setup</a>
        <a href={`${process.env.REACT_APP_FRONT_END_URL}/guides?guide-name=bluestacks-setup`}>BlueStacks Setup</a>
        <a href={`${process.env.REACT_APP_FRONT_END_URL}/guides?guide-name=osrs-client-setup`}>OSRS Setup</a>
        <a href={`${process.env.REACT_APP_FRONT_END_URL}/guides?guide-name=scripts`}>Script Setup</a>
      </StyledMobileGuideBar>
      {
        RenderGuide()
      }
    </StyledGuidePage>
  )
}

export default Guides