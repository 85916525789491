import { Button } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { authedApi } from '../../../api'
import useAuth from '../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import FloatingImage from '../../../components/FloatingImage/FloatingImage'
import downloadImg from '../../../assets/download_icon.png'
import AnimatedText from '../../../components/LetterHover/LetterHover'
import { printDebug } from '../../../utils/printDebug'
import GuideLinkTile from '../../../components/LinkTile/GuideLinkTile'
import img1 from '../../../assets/client_home.png'
import img2 from '../../../assets/displaySettings.png'
import img3 from '../../../assets/osrsApp.png'
import img4 from '../../../assets/skills_image.png'
import { StyledLinkTileWrapper } from './InstallPage.styles'

const InstallPage = () => {
    const navigate = useNavigate()
    const isAuthed = useAuth()
    const userHasPurchased = isAuthed?.userStripe?.purchases?.length

    const handleDownload = async () => {
        try {

            if (isAuthed && userHasPurchased) {
                const payload = {
                    awsDownloadKey: process.env.REACT_APP_AWS_DOWNLOAD_KEY
                }
                printDebug(`aws Download Key: ${payload.awsDownloadKey}`)
                const response = await authedApi.post('/product/download', payload)

                if (response.data.statusCode == 200) {
                    printDebug(`Got response: ${JSON.stringify(response.data.payload)}`);
                    window.open(response.data.payload)
                }
            } else {
                navigate('/buy')
            }

        } catch (error) {
            printDebug(`ERROR: ${error}`);
        }
    }

    useEffect(() => {
        printDebug(`isAuthed: ${JSON.stringify(isAuthed)}`);
        printDebug(`haspurchase? ${JSON.stringify()}`);

    }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem'}}>
        <AnimatedText text={'Installation'} elementType='h2' />
        <FloatingImage src={downloadImg} />
        <Button onClick={handleDownload} size='large' variant='contained'>Download Client</Button>

        <StyledLinkTileWrapper>
            <GuideLinkTile linkPath='dragonpy-setup' img={img1} title={'DragonPy Setup'} description={'Configure your DragonPy OSRS mobile bot client to use the scripts.'} hoverText='Setup DragonPy' />     
            <GuideLinkTile linkPath='bluestacks-setup' img={img2} title={'BlueStacks Setup'} description={'Configure the BlueStacks 5 mobile emulator to use DragonPy.'} hoverText='Setup BlueStacks mobile emulator' />     
            <GuideLinkTile linkPath='osrs-client-setup' img={img3} title={'OSRS Setup'} description={'Configure the OSRS mobile app in the emulator to use the DragonPy client.'} hoverText='Setup OSRS client'/>     
            <GuideLinkTile linkPath='scripts' img={img4} title={'Script Guides'} description={'Read more details about individual scripts.'} hoverText='Learn how to use scripts' />     
        </StyledLinkTileWrapper>
    </div>
  )
}

export default InstallPage