import React from 'react'

const Unauthorized = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem'}}>
      <h2>⛔ Unauthorized ⛔</h2>
    </div>
  )
}

export default Unauthorized