import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import NewsFeed from '../../../components/NewsFeed/NewsFeed'
import UpdatesFeed from '../../../components/UpdatesFeed/UpdatesFeed'
import useAuth from '../../../hooks/useAuth'
import { printDebug } from '../../../utils/printDebug'
import { AuthedHomeContent, AuthedHomeHeader } from './Home.styles'

const Home = () => {
  const auth = useAuth()
  const location = useLocation()

  useEffect(() => {
    printDebug(`[Home (Authed)] - Location: ${JSON.stringify(location)}` );
  },[])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4rem'}}>
      <AuthedHomeHeader>
        <h1>Welcome, <span style={{ color: 'gray'}}><em>{auth.userName}</em></span></h1>
        </AuthedHomeHeader> 
        <AuthedHomeContent>
          <div className='left'>
            <NewsFeed />
          </div>
          <div className='right'>
            {/* <SubStatus /> */}
            <UpdatesFeed />
          </div>
        </AuthedHomeContent>
    </div>
  )
}

export default Home