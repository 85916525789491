import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as AuthApi from '../../api/APIs/Auth.api';
import { printDebug } from '../../utils/printDebug';
import { setStateFromToken } from '../../utils/setStateFromToken';
import { setAlert } from './Alert.slice';

export const registerUser = createAsyncThunk(
  '/auth/register',
  async (registerForm, { dispatch }) => {
    printDebug(
      `[Auth Slice]: registerUser async thunk calling api.registerUser(formData)`
    );
    try {
      const response = await AuthApi.registerUser(registerForm);
      localStorage.setItem('isAuthed', response.headers.isauthed);
      const { message } = response.data;
      dispatch(
        setAlert({
          severity: 'success',
          isOpen: true,
          message,
        })
      );
      const accessToken = response.data.payload;
      return accessToken;
    } catch (error) {
      dispatch(
        setAlert({
          severity: 'error',
          isOpen: true,
          message: error.response.data.errorMessage,
        })
      );
      throw error.response.data.errorMessage;
    }
  }
);

export const loginUser = createAsyncThunk(
  '/auth/login',
  async (loginForm, { dispatch }) => {
    printDebug(
      `[Auth Slice]: loginUser async thunk fired - calling api.loginUser(${JSON.stringify(
        loginForm
      )})`
    );
    try {
      const response = await AuthApi.loginUser(loginForm);
      printDebug(
        `[Auth slice] - login response.headers: ${JSON.stringify(
          response.headers
        )}`
      );
      localStorage.setItem('isAuthed', response.headers.isauthed);
      const { message } = response.data;
      dispatch(setAlert({ isOpen: true, message, severity: 'success' }));
      const accessToken = response.data.payload;
      return accessToken;
    } catch (error) {
      dispatch(
        setAlert({
          isOpen: true,
          message: error.response.data.errorMessage,
          severity: 'error',
        })
      );
      throw error.response.data.errorMessage;
    }
  }
);

export const socialSignIn = createAsyncThunk(
  '/auth/socialSignIn',
  async (socialUser, { dispatch }) => {
    printDebug(
      `[Auth slice] - socialSignIn fired with socialUser: ${JSON.stringify(
        socialUser
      )}`
    );
    try {
      const response = await AuthApi.socialSignIn(socialUser);
      // return access token
      return response.data.payload;
    } catch (error) {
      dispatch(
        setAlert({
          isOpen: true,
          message: error.response.data.errorMessage,
          severity: 'error',
        })
      );
      throw error.response.data.errorMessage;
    }
  }
);

export const logoutUser = createAsyncThunk(
  '/auth/logout',
  async (_, { dispatch }) => {
    printDebug(
      `[Auth Slice] - logoutUser async fired - calling api.logoutUser`
    );
    try {
      await AuthApi.logoutUser();
      dispatch(
        setAlert({
          isOpen: true,
          message: 'Successfully signed user out.',
          severity: 'success',
        })
      );
      localStorage.clear();
      return;
    } catch (error) {
      dispatch(
        setAlert({
          isOpen: true,
          message: error.response.data.errorMessage,
          severity: 'error',
        })
      );
      throw error.response.data.errorMessage;
    }
  }
);

export const requestRecovery = createAsyncThunk(
  '/auth/recover',
  async (email, { dispatch }) => {
    printDebug(
      `[Auth slice] - requestRecovery async thunk fired with email: ${email}`
    );

    try {
      const response = await AuthApi.requestReset(email);
      dispatch(
        setAlert({
          isOpen: true,
          message: response.data.message,
          severity: 'success',
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setAlert({
          isOpen: true,
          message: error.response.data.errorMessage,
          severity: 'error',
        })
      );
      throw error.response.data.errorMessage;
    }
  }
);

export const validateRecoveryUser = createAsyncThunk(
  '/auth/validateResetUser/:id/:resetToken',
  async (_, { dispatch }) => {
    printDebug(`[Auth slice] - validateRecoveryUser: async thunk fired!`);
    try {
      const response = await AuthApi.validateRecoveryUser();
      return response.data.payload;
    } catch (error) {
      dispatch(
        setAlert({
          isOpen: true,
          message: error.response.data.errorMessage,
          severity: 'error',
        })
      );
      throw error.response.data.errorMessage;
    }
  }
);

export const resetPassword = createAsyncThunk(
  '/auth/resetPassword/:id/:resetToken',
  async (pwResetForm, { dispatch }) => {
    printDebug(`[Auth slice] - resetPassword: async thunk fired!`);
    try {
      const response = await AuthApi.resetPassword(pwResetForm);
      printDebug(
        `[Auth slice] - resetPassword: back with response: ${JSON.stringify(
          response
        )}`
      );
      dispatch(
        setAlert({
          isOpen: true,
          message: response.data.message,
          severity: 'success',
        })
      );
      return response.data.payload;
    } catch (error) {
      printDebug(`error: ${error.response.data}`);
      dispatch(
        setAlert({
          isOpen: true,
          message: error.response.data.errorMessage,
          severity: 'error',
        })
      );
      throw error.response.data.errorMessage;
    }
  }
);

const authSlice = createSlice({
  name: 'Auth',
  initialState: {
    authFormState: 'login',
    userId: null,
    userName: null,
    userEmail: null,
    userIsSocial: null,
    userIsVerified: false,
    userProfileImg: null,
    userRoles: [],
    userIsLoading: false,
    userAccessToken: null,
    userStripe: {
      tempSessionId: null,
      purchases: null,
    },
  },

  reducers: {
    setAuthFormState: (state, action) => {
      state.authFormState = action.payload;
    },
    setUserFromToken: (state, action) => {
      printDebug(
        `🔪 [Auth slice] - Setting user from token: ${JSON.stringify(action)}`
      );
      state = setStateFromToken(state, action);
    },
    setAccessToken: (state, action) => {
      state.userAccessToken = action.payload;
    },
    setUserIsLoading: (state, action) => {
      // debugger;
      printDebug(
        `🔪 [Auth slice] - setUserIsLoading: action.payload = ${action.payload}`
      );
      state.userIsLoading = action.payload;
    },
    clearAuthUser: (state, action) => {
      localStorage.setItem('isAuthed', false);
      state.authFormState = 'login';
      state.userId = null;
      state.userName = null;
      state.userEmail = null;
      state.userIsSocial = null;
      state.userIsVerified = false;
      state.userProfileImg = null;
      state.userRoles = [];
      state.userIsLoading = false;
      state.userAccessToken = null;
    },
  },

  extraReducers: builder => {
    // REGISTER
    // (Register) PENDING
    builder.addCase(registerUser.pending, (state, action) => {
      state.userIsLoading = true;
    });
    // (Register) FULFILLED
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state = setStateFromToken(state, action);
    });
    // (Register) REJECTED
    builder.addCase(registerUser.rejected, (state, action) => {
      printDebug(
        `registerUser rejected with action.payload ${JSON.stringify(
          action.error.message
        )}`
      );
      state.userIsLoading = false;
    });

    // LOGIN
    // (Login) PENDING
    builder.addCase(loginUser.pending, (state, action) => {
      printDebug(`⏳ [Auth Slice] - login PENDING builder fired!`);
      state.userIsLoading = true;
    });
    // (Login) FULFILLED
    builder.addCase(loginUser.fulfilled, (state, action) => {
      printDebug(`✅ [Auth Slice] - login FULFILLED builder fired!`);
      // Pass state and token - decode token - set state properties - return state obj
      state = setStateFromToken(state, action);
    });
    // (Login) REJECTED
    builder.addCase(loginUser.rejected, (state, action) => {
      printDebug(`❌ [Auth Slice] - login REJECTED builder fired!`);
      state.userIsLoading = false;
    });
    builder.addCase(socialSignIn.pending, (state, action) => {
      printDebug(`⏳ [Auth slice] - socialSignIn PENDING builder fired...`);
      state.userIsLoading = true;
    });
    builder.addCase(socialSignIn.fulfilled, (state, action) => {
      printDebug(`✅ [Auth slice] - socialSignIn FULFILLED builder fired...`);
      state = setStateFromToken(state, action);
    });
    builder.addCase(socialSignIn.rejected, (state, action) => {
      printDebug(`❌ [Auth slice] - socialSignIn REJECTED builder fired...`);
      state.userIsLoading = false;
    });

    // LOGOUT
    // (Logout) PENDING
    builder.addCase(logoutUser.pending, (state, action) => {
      printDebug(`⏳ [Auth Slice] - logout PENDING builder fired'`);
      state.userIsLoading = true;
    });
    // (Logout) FULFILLED
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      printDebug(`✅ [Auth Slice] - logout FULFILLED! - clearing user state`);
      state.userId = null;
      state.userAccessToken = null;
      state.userIsLoading = false;
    });
    // (Logout) REJECTED
    builder.addCase(logoutUser.rejected, (state, action) => {
      printDebug(`❌ [Auth Slice] - logout REJECTED!`);
      state.userIsLoading = false;
    });

    // REQUEST RESET PASSWORD
    builder.addCase(requestRecovery.pending, (state, action) => {
      printDebug(`🔪 [Auth slice]- requestRecovery PENDING fired`);
      state.userIsLoading = true;
    });
    builder.addCase(requestRecovery.fulfilled, (state, action) => {
      state.authFormState = 'login';
      state.userIsLoading = false;
    });
    builder.addCase(requestRecovery.rejected, (state, action) => {
      state.userIsLoading = false;
    });

    builder.addCase(validateRecoveryUser.fulfilled, (state, action) => {
      printDebug(
        `✅ [Auth Slice] - validateRecoveryUser: builder fulfilled! action.payload: ${action.payload}`
      );
    });

    // RESET PASSWORD
    builder.addCase(resetPassword.pending, (state, action) => {
      state.userIsLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      printDebug(
        `✅ [Auth Slice] - resetPassword Builder: Fired! ${action.payload}`
      );
      state = setStateFromToken(state, action);
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.userIsLoading = false;
    });
  },
});

export const {
  setAuthFormState,
  setAccessToken,
  setUserIsLoading,
  setUserFromToken,
  clearAuthUser,
} = authSlice.actions;
export default authSlice.reducer;
