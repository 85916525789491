import styled from 'styled-components';
import { dtMaxSize } from '../../../GlobalStyles';

export const AuthedHomeHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* justify-content: center; */
  align-items: center;
  /* margin-top: 6rem; */
`;

export const AuthedHomeContent = styled.div`
  display: flex;
  padding: 0 2rem;

  .left {
    width: 50%;
    display: flex;
    justify-content: center;
    @media (max-width: ${dtMaxSize}) {
      width: 100%;
    }
  }
  .right {
    width: 50%;
    display: flex;
    justify-content: center;
    @media (max-width: ${dtMaxSize}) {
      width: 100%;
    }
  }

  @media (max-width: ${dtMaxSize}) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;
