import { Button } from '@mui/material';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { printDebug } from '../../../utils/printDebug';

const VerifyEmail = () => {
    const userIsVerified = useSelector(state => state.auth.userIsVerified)
    const authedEmail = useSelector(state => state.auth.userEmail)
    const navigate = useNavigate()

  useEffect(() => {
    printDebug(`[VerifyEmail] - Rendered!`);
    
  }, [])

  useEffect(() => {
    printDebug(`[VerifyEmail] - userIsVerified state change render`);
    userIsVerified && navigate('/home') 
  }, [userIsVerified])
  
  return (
    <>
      <div style={{width: '100%', height: '40vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '3rem'}}>
        <h2>
          Please confirm your email by clicking the link sent to the email you registered with.
        </h2>
        <h3>⚠️ Check your spam folder - Link Expires in 6 Hours ⚠️</h3>
        <div style={{display: 'flex', gap: '2rem', marginTop: '2rem'}}>
          <h4>Email Provided:</h4>
          <p><em>{authedEmail}</em></p>
        </div>
        <Button variant='outlined' color='warning' href='https://discord.gg/uqyyHSBvqt'>Help</Button>
      </div>

    </>
  )
}

export default VerifyEmail