import React from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import { useSelector } from 'react-redux';

const AuthInput = ({ half, type, name, label, autoFocus, autoComplete, handleChange, handleShowPass }) => {
  const userIsLoading = useSelector(state => state.auth.userIsLoading)
  
  return (
    <Grid item xs={12} sm={half ? 6 : 12}>
      <TextField
        required
        type = {type}
        name = {name}
        label = {label}
        autoFocus = {autoFocus}
        autoComplete= {autoComplete}
        onChange = {handleChange}
        variant = 'filled'
        fullWidth
        disabled={userIsLoading}
        InputProps={
          name === 'password'
            ? {
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleShowPass}>
                      {type === 'password' || 'newPass' ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : null
        }
       />
    </Grid>
  )
}

export default AuthInput