import styled from 'styled-components';
import { dtMaxSize } from '../../../GlobalStyles';

export const StyledReviewContainer = styled.div`
  display: flex;
  gap: 5rem;

  @media (max-width: ${dtMaxSize}) {
    flex-direction: column;
  }
`;
