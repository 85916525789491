import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import { printDebug } from '../../../utils/printDebug';
import AnimatedText from '../../LetterHover/LetterHover';
import { StyledNavLink, LinkItem, LinkList } from '../Navbar.styles';
import linkData from './linkData';

const NavLinks = () => {
  const userId = useSelector(state => state.auth.userId)
  const currPath = window.location.pathname

  useEffect(() => {
    printDebug(`currPath: ${currPath}`)

  }, [currPath])

  return (
    <nav>
        {
          linkData.map((item, index) => {
              if (item.protected && userId)
              {
                return (
                  <StyledNavLink className={ currPath == item.path ? 'active' : 'inactive'}  key={index} to={item.path} >
                    <AnimatedText text={item.text} />
                    {/* {item.text} */}
                  </StyledNavLink>
                )
              }
              else if (!item.protected) {
                return (
                  <StyledNavLink className={ currPath == item.path ? 'active' : 'inactive'}  key={index} to={item.path} >
                    <AnimatedText text={item.text} />
                    {/* {item.text} */}
                  </StyledNavLink>
                )
              }
          })
        }
    </nav>
  )
}

export default NavLinks