import { Badge, Button } from '@mui/material'
import React, {useState, useEffect} from 'react'
import { BuyBox, DisclaimerBox, PerkBox, PerkItem, PerkList, SubBtn, SubBtnBox, SubBtnContainer, SubHeader } from './BuyPage.styles'
import { authedApi } from '../../../api'
import {useNavigate} from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import Chip from '@mui/material/Chip';
import { printDebug } from '../../../utils/printDebug'
import { StripePrices } from './StripePrices'

const BuyPage = () => {
    const [isNearCambridge, setIsNearCambridge] = useState(false);

    const authedUser = useAuth()
    const navigate = useNavigate()

    const { originalPrice: ogMonthlyPrice, actualPrice: actualMonthlyPrice, lookupKey: monthlyLookupKey } = StripePrices.monthly 
    const { actualPrice: actualTriMonthlyPrice, lookupKey: triMonthlyLookupKey } = StripePrices.triMonthly 
    const { actualPrice: actualAnnualPrice, lookupKey: annualLookupKey } = StripePrices.annual 
    const { originalPrice: ogLifeTimePrice, actualPrice: actualLifeTimePrice, lookupKey: lifeTimeLookupKey } = StripePrices.lifeTime 

    const [ purchaseState, setPurchaseState ] = useState({
        currSubPrice: 32,
        currLookupKey: triMonthlyLookupKey,
        currSelChip: '3-Month'
    })

    const isNearCity = async () => {
        fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
          const userLat = data.latitude;
          const userLng = data.longitude;

          printDebug(`RES: ${JSON.stringify(data)}`);
  
          fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${userLat}&lon=${userLng}&appid=${process.env.REACT_APP_OPEN_WEATHER_API_KEY}`)
            .then(response => response.json())
            .then(data => {
              const cambridgeLat = 47.6743;
              const cambridgeLng = 117.1124;
              const R = 3958.8; // Earth's radius in miles
  
              const latDiff = (cambridgeLat - userLat) * Math.PI / 180;
              const lngDiff = (cambridgeLng - userLng) * Math.PI / 180;
              const a = Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
                        Math.cos(userLat * Math.PI / 180) * Math.cos(cambridgeLat * Math.PI / 180) *
                        Math.sin(lngDiff / 2) * Math.sin(lngDiff / 2);
              const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
              const distance = R * c;
  
              if (distance <= 100) {
                setIsNearCambridge(true);
              } else {
                setIsNearCambridge(false);
              }
            })
            .catch(error => console.error(error));
        })
        .catch(error => console.error(error));
    }

    const { currSubPrice } = purchaseState

    const handleSubscription = async (e) => {
        printDebug(`[BuyPage] - Making POST request to authedApi /stripe/create-checkout-session for redirect to portal for appropriate price on success`);
        try {
            if (authedUser.userId) {
                // Button is either 'subscription' or 'lifetime'
                const btnType = e.target.value
                let checkoutPayload

                if (btnType == 'lifetime') {
                    checkoutPayload = {
                        lookup_key: StripePrices.lifeTime.lookupKey,
                        purchaseType: 'singlePayment',
                        purchaseTerm: 'lifetime',
                        authedUser
                    }
                } else {
                    checkoutPayload = {
                        lookup_key: purchaseState.currLookupKey,
                        purchaseType: 'subscription',
                        purchaseTerm: purchaseState.currSelChip,
                        authedUser
                    }
                }

                printDebug(`[handleSubscription] - checkoutPayload: ${JSON.stringify(checkoutPayload)}`)
                // Make POST request to create-checkout-session & return with redirect link to Stripe buy portal
                const response = await authedApi.post('/stripe/create-checkout-session', checkoutPayload)
                printDebug(`response: ${JSON.stringify(response)}`);
                // If POST request successful...
                if (response.data.statusCode === 200) {
                    // Open a new tab with the Stripe purchase portal
                    window.open(response.data.payload.sessionUrl)
                }
            } else {
                printDebug(`Not authed`);
                navigate('/auth')
            }

        } catch (error) {
            printDebug(`error: ${error}`);
        }
    }

    const handleChipClick = (e) => {
        // console.debug(e)
        // Detect which subscription duration chip was clicked
        const chipInnerText = e.target.innerText
        printDebug(`chip inner text: ${chipInnerText}`);

        // Set * Price * ChipSel * LookUpKey state based on which chip
        switch (chipInnerText) {
            case '3-Month':
                // TRIMONTHLY
                printDebug(`Selected 3-Month chip`);
                setPurchaseState(prev => ({ ...prev,
                    currSubPrice: actualTriMonthlyPrice,
                    currLookupKey: triMonthlyLookupKey,
                    currSelChip: chipInnerText
                }))
                break
            case 'Monthly':
                // MONTHLY
                printDebug(`Selected Monthly chip`);
                setPurchaseState(prev => ({ ...prev,
                    currSubPrice: actualMonthlyPrice,
                    currLookupKey: monthlyLookupKey,
                    currSelChip: chipInnerText
                }))
                // setIsMonthlyChipSel(true)
                // setCurrPrice(49)
                // setCurrLookupKey(monthlyLookupKey)
                break
            case 'Annual':
                // ANNUAL
                printDebug(`Selected Annual chip`);
                setPurchaseState(prev => ({ ...prev,
                    currSubPrice: actualAnnualPrice,
                    currLookupKey: annualLookupKey,
                    currSelChip: chipInnerText
                }))
                break
            default:
                printDebug(`Something went wrong selecting a monthly duration chip`)
        }

        printDebug(`Finished updating purchaseState: ${JSON.stringify(purchaseState)}`);
    }

    useEffect(() => {
        isNearCity()
        printDebug(`[BuyPage] - isNearCambridge: ${isNearCambridge}`);
    }, [])

    useEffect(() => {
        printDebug(`[BuyPage] - purchaseState Update Caused Re-Render ${JSON.stringify(purchaseState)}`);
    }, [purchaseState])

  return (
    <>
        <BuyBox>
            <PerkBox>
                <PerkList>
                    <PerkItem>📜 Access to 40+ Custom Scripts</PerkItem>
                    <PerkItem>🎨 Custom, Intuitive Desktop GUI</PerkItem>
                    <PerkItem>🔓 No Sensitive Information Required</PerkItem>
                    <PerkItem>🤖 Extensive Anti-ban (No Reported Bans)</PerkItem>
                    <PerkItem>🥇 Uses Official OldSchool RuneScape Mobile Client</PerkItem>
                    <PerkItem>💪🏼 Most Reliable OldSchool RuneScape Account Builder</PerkItem>
                </PerkList>
            </PerkBox>

            <SubBtnContainer>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem'}}>
                    <SubBtnBox>
                        <SubBtn>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '.5rem'}}>
                            <SubHeader>Monthly</SubHeader>
                            <p><em>(All-Access)</em></p>
                            </div>
                            
                            <div style={{ display: 'flex', gap: '2rem'}}>
                                <h1 style={{ color: '#5c5c5c52', textDecoration: 'line-through' }}>${ogMonthlyPrice}</h1>
                                <h1 style={{ color: '#188d36', fontSize: '2.5rem' }}>${currSubPrice}</h1>
                            </div>
                            <Button value='subscription' size='large' variant='contained' color='success' onClick={handleSubscription}>Subscribe</Button>
                        </SubBtn>
                    </SubBtnBox>
                    <div style={{ display: 'flex', gap: '3rem' }}>
                        <Chip label="Monthly" color='success' variant={purchaseState.currSelChip === 'Monthly' ? 'filled' : 'outlined'} onClick={handleChipClick}/>
                        <Badge style={{ scale: '115%' }} badgeContent={'⭐'} color='action'>
                            <Chip label="3-Month" color='success' variant={purchaseState.currSelChip === '3-Month' ? 'filled' : 'outlined'} onClick={handleChipClick} />
                        </Badge>
                        <Chip label="Annual" color='success' variant={purchaseState.currSelChip === 'Annual' ? 'filled' : 'outlined'} onClick={handleChipClick} />
                    </div>
                </div>

                <SubBtnBox>
                    <SubBtn>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '.5rem'}}>
                            <SubHeader>Life-Time</SubHeader>
                            <p><em>(All-Access)</em></p>
                            </div>
                        
                        <div style={{ display: 'flex', gap: '2rem'}}>
                        <h1 style={{ color: '#5c5c5c52', textDecoration: 'line-through' }}>${ogLifeTimePrice}</h1>
                        <h1 style={{ color: '#2d67bd', fontSize: '2.5rem' }}>${actualLifeTimePrice}</h1>
                        </div>
                        <Button value='lifetime' size='large' variant='contained' color='primary' onClick={handleSubscription}>Purchase</Button>
                    </SubBtn>
                </SubBtnBox>
            </SubBtnContainer>

        </BuyBox>
        
        <br />

        <div style={{ display: 'flex', justifyContent: 'center', opacity: '25%', marginTop: '6rem', marginBottom: '4rem'}}>
            <hr style={{width: '50%'}} />
        </div>
        

        <div>
            <DisclaimerBox>
                <h1>🚨 DISCLAIMERS 🚨</h1>
                <br />
                <h3 style={{color: '#535353', textAlign: 'center'}}>🧪 Verify Requirements Before Buying</h3>
                <h4 style={{ textAlign: 'center', color: '#7e7e7e'}}>
                    Follow <a href="/setup">THIS GUIDE</a> to install BlueStack 5 Android Mobile Emulator to verify your system meets the requirements <span style={{ textDecoration: 'underline'}}>BEFORE PURCHASING</span>. 
                </h4>
                <p style={{fontWeight: '500', textAlign: 'center', color: '#7e7e7e'}}>While Subscription cancellations are always possible, we won't issue refunds for something which you can easily verify.</p>
                <Button href='/setup' variant='contained' color='error' size='large'>Verify Setup</Button>
                <br />
                <h3 style={{color: '#535353'}}>📈 Prices Subject to Change</h3>
                <h4 style={{color: '#7e7e7e'}}>
                    In the event of a price increase, we will honor your original subscription price until cancelled.
                </h4>
            </DisclaimerBox>
        </div>
    </>
  )
}

export default BuyPage