import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const StyledImg = styled.img`
    width: ${({imgWidth}) => imgWidth ? imgWidth : '100%'};
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    &.fade-in-up {
        opacity: 1;
        transform: translateY(0);
    }
    
`;

const FadingImage = ({ src, img, alt, imgWidth }) => {
    var imageData
    src ? imageData = src : imageData = img
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { rootMargin: "0px" }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [imgRef]);

  return (
    <StyledImg
      ref={imgRef}
      src={imageData}
      alt={alt}
      className={isVisible ? "fade-in-up" : ""}
      imgWidth={imgWidth}
    />
  );
};

export default FadingImage;