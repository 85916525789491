export const supportedMinigames = [
  {
    minigameName: 'Nightmare Zone',
  },
  {
    minigameName: 'Fishing Trawler',
  },
  {
    minigameName: 'Tithe Farming',
  },
];
