import { Button, ButtonGroup, Input } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ArrowDropUp } from '@mui/icons-material';
import { printDebug } from '../../utils/printDebug';

const LicenseCount = ({count, incCount, decCount, labelText}) => {

    // let [count, setCount] = useState(2)

    // const incrementCount = () => {
    //     setCount(count => count+=1)
    // }

    // const decrementCount = () => {
    //     setCount(count => count-=1)
    // }

    useEffect(() => {
        printDebug('Updating LicenseCount component due to count update')
    }, [count])

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', fontSize: '1.2rem', marginTop: '1rem'}}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <ButtonGroup>
                <Button onClick={decCount}>
                    <p style={{ display: 'flex'}}>
                    <ArrowDropDownIcon />
                    </p>
                </Button>
                <Button>{count}</Button>
                <Button onClick={incCount}>
                    <ArrowDropUp />
                </Button>
            </ButtonGroup>
        </div>
        <div>
            <p style={{ fontSize: '1.25rem'}}>{labelText}</p>
        </div>
    </div>
  )
}

export default LicenseCount