import { Button } from '@mui/material'
import React from 'react'
import LicenseStatus from '../../../components/LicenseStatus/LicenseStatus'
import useAuth from '../../../hooks/useAuth'
import { AccountPageContainer, AccountPageHeader, StyledAccountPage } from './AccountPage.styles'

const AccountPage = () => {
  const auth = useAuth()
  return (
    <>
    <StyledAccountPage>
      <AccountPageHeader>
        <h1>Account Dashboard</h1>

      </AccountPageHeader>
      <AccountPageContainer>
        <div className='left'>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', justifyContent: 'center'}}>
            <p style={{ color: 'gray'}}>Name: <em>{auth.userName}</em></p>
            <p style={{ color: 'gray'}}>Email: <em>{auth.userEmail}</em></p>
            <Button variant='contained' color='success' href='/buy' style={{maxWidth: '20rem'}}>Purchase</Button>
            <Button variant='outlined' color='secondary' href="https://discord.gg/uqyyHSBvqt" style={{maxWidth: '20rem'}}>Join Discord</Button>
          </div>  
        </div> 
        <div className='right'>
          <LicenseStatus />
        </div> 
        </AccountPageContainer> 
    </StyledAccountPage>
    </>
  )
}

export default AccountPage