import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const GuideLinkTile = ({img, title, description, hoverText, linkPath}) => {
  return (
  <Card sx={{ maxWidth: 300, minWidth: 300 }}>
    <CardMedia
      sx={{ height: 140 }}
      image={img}
      title={hoverText}
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
      {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </CardContent>
    <CardActions style={{display: 'flex', justifyContent: 'center', padding: '1rem 0'}}>
      <Button variant='outlined' color='info' size="small" href={`${process.env.REACT_APP_FRONT_END_URL}/guides?guide-name=${linkPath}`}>Read More</Button>
    </CardActions>
  </Card>
  )
}

export default GuideLinkTile