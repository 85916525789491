import React from 'react'
import GuideTemplate from '../GuideTemplate'

const OSRSGuide = () => {
  return (
    <GuideTemplate header='OSRS'>
        <h3><em>Interface</em></h3>
        <p>🔨 Under construction</p>
    </GuideTemplate>
  )
}

export default OSRSGuide